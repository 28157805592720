import { CATEGORIES_LOADING, CATEGORIES_LOAD_ERROR, CATEGORIES_LOAD_SUCCESS } from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstance from "../../helpers/axiosInstance"


export default (history) => (dispatch) => {
    dispatch({
        type: CATEGORIES_LOADING,
    });
    axiosInstance(history)
        .get('/api/v1/app/get_all_categories')
        .then(res => {
            // console.log('res :>> ', res);
            dispatch({
                type: CATEGORIES_LOAD_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            // console.log('err :>> ', err);
            dispatch({
                type: CATEGORIES_LOAD_ERROR,
                payload: err.response ? err.response.data : CONNECTION_ERROR,
            });
        })
}


