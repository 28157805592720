import React, { useContext, useEffect, useRef, useState } from "react"
import CryptoJS from 'crypto-js'
import { StarIcon } from '@heroicons/react/solid'
import { useHistory, useParams } from "react-router-dom"
import ProductSlider from "../components/products/product/ProductSlider"
import ProductDetails from "../components/products/product/ProductDetails"
import Button from "../components/button/button"
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ProductQuantity from "../components/products/product/ProductQuantity"
import axiosInstance from "../helpers/axiosInstance"
import { GlobalContext } from "../context/Provider"
import BookingSlot from "../components/products/product/BookingSlot"
import toast, { Toaster } from 'react-hot-toast';
import { whatsAppNumberApp } from "../constants/config"


export const DatePick = () => {
   const [startDate, setStartDate] = useState(new Date());
   return (
      <DatePicker className="outline-none" selected={startDate} onChange={(date) => setStartDate(date)} />
   );
};


function ProductView() {

   const { cart, setCart } = useContext(GlobalContext)

   const history = useHistory();
   const { productId } = useParams()

   const [pageURL, setPageURL] = useState(0);
   const [isLoading, setLoading] = useState(false)
   const [index, setIndex] = useState(0)
   const [ikg, setIkg] = useState(0)
   const [kgName, setKgName] = useState("")
   const [kgId, setKgId] = useState(0)
   const [product, setProduct] = useState({})
   const [imgUrl, setImgUrl] = useState("")
   const [quantity, setQuantity] = useState(1);
   const [currPrice, setCurrPrice] = useState(""); //Selling Price
   const [originalPrice, setOriginalPrice] = useState(""); //Selling Price
   const [ogPrice, setOgPrice] = useState(""); //Actual Price
   const [ogCurrPrice, setOgCurrPrice] = useState("");//Actual Price
   const [offerPercent, setOfferPercent] = useState("");//Actual Price
   const [message, setMessage] = useState("");
   const [uploadImage, setUploadImage] = useState(null)
   const [uploadedImage, setUploadedImage] = useState("")
   const [uploadedImageUrl, setUploadedImageUrl] = useState("")
   const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0])
   const [selectedSlot, setSelectedSlot] = useState("")

   const myRef = useRef(null)
   const myKgRef = useRef(null)

   const kgTab = (ikg, kgName, kgId) => {
      setIkg(ikg)
      setKgName(kgName)
      setKgId(kgId)

      const price = myKgRef.current.children
      for (let i = 0; i < price.length; i++) {
         price[i].className = price[i].className.replace("bg-orange text-white border-orange", "bg-gray-50");
      }
      price[ikg].className = "bg-orange text-white border-orange px-4 border-2 py-1 rounded  cursor-pointer hover:bg-gray-400 hover:border-gray-400 transition duration-500 ease-in-out";
      setQuantity(1)
      setCurrPrice(product.prices[ikg].discount_price)
      setOriginalPrice(product.prices[ikg].discount_price)
      setOgPrice(product.prices[ikg].original_price)
      setOgCurrPrice(product.prices[ikg].original_price)
      setOfferPercent(product.prices[ikg].offer_percentage)
   };


   const handleTab = index => {
      setIndex(index)
      const images = myRef.current.children
      for (let i = 0; i < images.length; i++) {
         images[i].className = images[i].className.replace("opacity-100", "opacity-50");
      }
      images[index].className = "opacity-100 cursor-pointer rounded transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110";
   }

   const getProductViewData = () => {
      setLoading(true)
      axiosInstance(history)
         .get('/api/v1/app/get_product', {
            params: {
               product: productId
            }
         })
         .then(res => {
            let productData = res.data;
            setProduct(productData.product)
            setImgUrl(productData.product_base_url)
            setCurrPrice(productData.product.prices[ikg].discount_price)
            setOriginalPrice(productData.product.prices[ikg].discount_price)
            setOgPrice(productData.product.prices[ikg].original_price)
            setOgCurrPrice(productData.product.prices[ikg].original_price)
            setOfferPercent(productData.product.prices[ikg].offer_percentage)
            setKgName(productData.product.prices[ikg].title)
            setKgId(productData.product.prices[ikg].id)
         })
         .catch((err) => {
            // console.log('err :>> ', err);
         })
         .finally(() => {
            setLoading(false)
            myRef.current.children[index].className = "opacity-100 cursor-pointer rounded transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110";
            myKgRef.current.children[ikg].className = "bg-orange text-white border-orange px-4 border-2 py-1 rounded  cursor-pointer hover:bg-gray-400 hover:border-gray-400 transition duration-500 ease-in-out";
         })
   }

   useEffect(() => {
      if (productId) {
         getProductViewData()
         setPageURL(window.location.href);
      }
   }, [productId])


   function noscript(message) {
      var regex = /(<([^>]+)>)/ig;
      return message.replace(regex, "");
   }

   const addToCart = (item) => {
      if (item.selectedSlot) {
         if (cart.items?.length > 0) {
            if (cart.items[0]?.selectedDate == item.selectedDate && cart.items[0]?.selectedSlot == item.selectedSlot) {
               let cartItemsCpy = [...cart.items];
               let { id, kg } = item;
               let existingItem = cartItemsCpy.find(cartItem => cartItem.id == id && cartItem.kg == kg);
               if (existingItem) {
                  existingItem.quantity += item.quantity
                  existingItem.price += item.price
               } else {
                  cartItemsCpy.push(item)
               }
               let cartCopy = {
                  'items': cartItemsCpy,
                  'subTotal': cart.subTotal,
                  'promoCode': cart.promoCode,
               }
               setCart(cartCopy);
               let stringCart = JSON.stringify(cartCopy);
               var encryptedCart = CryptoJS.AES.encrypt(stringCart, 'secret-key@123').toString();
               localStorage.setItem("cart", encryptedCart)
               history.push('/cart')
            } else {
               toast.error('Please Choose Same Date and slot in Cart.')
            }
         } else {
            let cartItemsCpy = [...cart?.items];
            let { id, kg } = item;
            let existingItem = cartItemsCpy.find(cartItem => cartItem.id == id && cartItem.kg == kg);
            if (existingItem) {
               existingItem.quantity += item.quantity
               existingItem.price += item.price
            } else {
               cartItemsCpy.push(item)
            }
            let cartCopy = {
               'items': cartItemsCpy,
               'subTotal': cart.subTotal,
               'promoCode': cart.promoCode,
            }
            setCart(cartCopy);
            let stringCart = JSON.stringify(cartCopy);
            var encryptedCart = CryptoJS.AES.encrypt(stringCart, 'secret-key@123').toString();
            localStorage.setItem("cart", encryptedCart)
            history.push('/cart')
         }

      } else {
         toast.error('Please Select Slot.')
      }
   }

   const handleUploadPhoto = (image) => {
      const formData = new FormData()
      formData.append('image', image)
      axiosInstance(history).post('/api/v1/app/upload_image_photo_cake', formData)
         .then((res) => {
            toast.success('Image Uploaded Successfully.')
            setUploadedImage(res.data.image)
            setUploadedImageUrl(res.data.image_base_url)
         })
         .catch(err => console.log(err));
   }

   useEffect(() => {
      if (uploadImage !== null)
         handleUploadPhoto(uploadImage)
   }, [uploadImage])

   function orderNowHandle(item) {
      let message = "Thank you for choosing 𝐌𝐚𝐲𝐚𝐯𝐢 𝐂𝐚𝐤𝐞𝐬🎂,\r\n\r\n"
      // message = encodeURIComponent(`Name: ${item.name}( ${item.kgName} )\r\nQuantity: ${item.quantity}\r\nMessageOnCake: ${item.message || ""}\r\nDate: ${item.selectedDate + " " + item.selectedSlot}\r\nPhotoCakeImage: ${uploadedImageUrl + item.cakeImage || ""}\r\vReferenceLink:${pageURL}`);
      message += `Name: *${item.name}*\r\r( ${item.kgName} )\r\nQuantity: ${item.quantity}\r\n${item.message ? `MessageOnCake: ${item.message || ""}` : ``}\r\nDate: ${item.selectedDate + " " + item.selectedSlot}\r\n ${item.cakeImage ? `PhotoCakeImage: ${uploadedImageUrl + item.cakeImage || ""}` : ``}\r\nReferenceLink:${pageURL}`;
      let url = `https://wa.me/${whatsAppNumberApp}`;
      url += `?text=${encodeURI(message)}`;
      // let url = `https://web.whatsapp.com/send?phone=${whatsAppNumberApp}`;
      // url += `&text=${encodeURI(message)}&app_absent=0`;
      window.open(url);
   }

   function buyNowHandle(item) {
      if (item.selectedSlot) {
         addToCart(item)
         history.push({ pathname: "/checkout" })
      } else {
         toast.error('Please Select Slot.')
      }
   }

   const productViewLoader = () => {
      return (
         <div>
            <h1 className="text-black text-3xl mt-5 bg-gray-200 animate-pulse h-10 w-3/4"></h1>

            <div className="flex products-center mt-2">
               <div className="text-orange text-md flex products-center bg-gray-100 animate-pulse h-4 w-10"></div>  <div className="d-block text-gray-400 text-md ml-3 bg-gray-100 animate-pulse h-4 w-20"></div>
            </div>
            <div className="mt-4">
               <span className="inline-block bg-green text-sm text-white px-2 rounded bg-gray-200 animate-pulse h-5 w-16"></span>
               <strong className="block text-4xl mt-2 bg-gray-100 animate-pulse h-10 w-24"></strong>
            </div>

            <div className="mt-6">
               {/* <span className="inline-block text-base">Choose Weight</span> */}
               <div className="flex items-center mt-2 gap-3">
                  <div className="px-4 text-black border-2 py-1 rounded bg-gray-50 cursor-pointer transition duration-500 ease-in-out animate-pulse h-8 w-14"></div>
                  <div className="px-4 text-black border-2 py-1 rounded bg-gray-50 cursor-pointer transition duration-500 ease-in-out animate-pulse h-8 w-14"></div>
                  <div className="px-4 text-black border-2 py-1 rounded bg-gray-50 cursor-pointer transition duration-500 ease-in-out animate-pulse h-8 w-14"></div>
               </div>
            </div>



            <div className="mt-5">
               <div className="mt-5 sm:flex sm:items-center">
                  <div className="border py-3 pl-4 rounded-xl mt-2 bg-gray-200 animate-pulse h-10 w-1/3 mr-2"></div>
                  <div className="border py-3 pl-4 rounded-xl mt-2 bg-gray-200 animate-pulse h-10 w-1/3"></div>
                  <div className="border py-3 pl-4 rounded-xl mt-2 bg-gray-200 animate-pulse h-10 w-1/5 ml-3"></div>
               </div>
            </div>

            <div className="mt-5 flex items-center">
               <h1 className="py-3 px-8 rounded-lg inline-block text-center bg-darkRed animate-pulse w-1/4 h-12"></h1>
               <div className="w-5"></div>
               <h1 className="py-3 px-8 rounded-lg inline-block text-center bg-darkGreen animate-pulse w-1/4 h-12"></h1>
            </div>
         </div>
      )
   }


   // const thisProduct = ProductData.find(prod => prod.id === productId)
   const thisProduct = product;

   return (
      <>
         <Toaster />
         <div className="pb-20 border-b sm:pt-10 m-pb-30">
            <div className="container">

               <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                  <div>
                     <div>
                        {thisProduct ?
                           <ProductSlider data={thisProduct} tab={handleTab} myRef={myRef} imgIndex={index} imgUrl={imgUrl} offerPercent={offerPercent} isLoading={isLoading} /> : <img className="w-full rounded-md object-cover object-center" src={process.env.PUBLIC_URL + '/images/placeholder.png'} alt='' />
                        }
                     </div>
                  </div>

                  {
                     isLoading ? productViewLoader() :
                        <div>
                           <h1 className="text-black text-3xl mt-5">{thisProduct?.name}</h1>

                           < div className="flex products-center mt-2">
                              <div className="text-orange text-md flex products-center">{thisProduct.review_rating ? thisProduct.review_rating : "4.9"} <StarIcon className="h-5 w-5 ml-1" /></div>  <span className="d-block text-gray-400 text-md ml-3 hover:text-black">{thisProduct.review_rating_count ? thisProduct.review_rating_count : "1"} Reviews</span>
                           </div>
                           {
                              currPrice ? <div className="mt-4">
                                 <span className="inline-block bg-green text-sm text-white px-2 rounded">Price</span>
                                 <div className="flex gap-1 items-center mt-2">
                                    <strong className="block text-4xl">₹{currPrice ? currPrice : ""}</strong>
                                    {
                                       ogCurrPrice > currPrice && <strong className="sm:text-1xl text-sm text-gray font-normal block line-through">₹{ogCurrPrice ? ogCurrPrice : ''}</strong>
                                    }

                                 </div>
                              </div> : ""
                           }

                           <div className="mt-6">
                              <span className="inline-block text-base">Choose Weight</span>
                              <div className="flex items-center mt-2 gap-3" ref={myKgRef}>
                                 {thisProduct?.prices?.map((kg, index) => (
                                    <div key={index} onClick={() => kgTab(index, kg.title, kg.id)} className="px-4 text-black border-2 py-1 rounded bg-gray-50 cursor-pointer hover:bg-gray-400 hover:border-gray-400 transition duration-500 ease-in-out">
                                       {kg.title}
                                    </div>
                                 ))}
                              </div>
                           </div>

                           <div className="mt-5">
                              <div className="mt-5 sm:flex sm:items-center">
                                 {thisProduct?.time_delay ? <BookingSlot setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedSlot={setSelectedSlot} timeDelay={thisProduct.time_delay} /> : ''}
                                 <div className="w-full sm:w-auto">
                                    <ProductQuantity setQuantity={setQuantity} quantity={quantity} setCurrPrice={setCurrPrice} currPrice={currPrice} originalPrice={originalPrice} ogPrice={ogPrice} ogCurrPrice={ogCurrPrice} setOgCurrPrice={setOgCurrPrice} />
                                 </div>
                              </div>
                           </div>

                           <div className="mt-5">
                              <span className="block text-base mr-5">Message On Cake</span>
                              <textarea className="border w-full resize-none h-20 rounded-xl mt-2 p-4 outline-none" onChange={(e) => {
                                 setMessage(e.target.value)
                              }} placeholder="Please make sure..."></textarea>
                           </div>
                           {thisProduct?.photo_cake ?
                              <div className="mt-5 mb-10">
                                 <span className="block text-base mr-5 mb-2">Upload Your Photo</span>
                                 <div className="relative px-6 py-3 bg-blue text-white rounded inline-block">
                                    <input className="cursor-pointer absolute top-0 right-0 left-0 bottom-0 opacity-0 z-10" onChange={(e) => { setUploadImage(e.target.files[0]) }} type="file" name="" id="" />
                                    Upload File
                                 </div>
                                 {uploadedImage &&
                                    <div>
                                       <img className="w-24 h-24" src={uploadedImageUrl + uploadedImage} alt="" />
                                    </div>
                                 }
                              </div> : ""
                           }

                           <div className="mt-5 flex items-center">
                              <Button className="ml-3" click={() => {
                                 const item = {
                                    id: thisProduct.id,
                                    subId: kgId,
                                    name: thisProduct.name,
                                    price: currPrice,
                                    kg: ikg + 1,
                                    kgName: kgName,
                                    quantity: quantity,
                                    image: imgUrl + thisProduct.image,
                                    cakeImage: uploadedImage,
                                    message: noscript(message),
                                    originalPrice: originalPrice,
                                    selectedDate: selectedDate,
                                    selectedSlot: selectedSlot
                                 }
                                 orderNowHandle(item)
                              }} bgHover="darkGreen" text="Order Now" bgColor="green" textColor="white" icon="fa fa-whatsapp" />
                              {/* <Button bgHover="darkRed" text="Add to Cart" bgColor="red" textColor="white" click={() => {
                                 const item = {
                                    id: thisProduct.id,
                                    subId: kgId,
                                    name: thisProduct.name,
                                    price: currPrice,
                                    kg: ikg + 1,
                                    kgName: kgName,
                                    quantity: quantity,
                                    image: imgUrl + thisProduct.image,
                                    cakeImage: uploadedImage,
                                    message: noscript(message),
                                    originalPrice: originalPrice,
                                    selectedDate: selectedDate,
                                    selectedSlot: selectedSlot
                                 }
                                 addToCart(item)
                              }} /> */}
                              <div className="w-5"></div>
                              {/* <Button className="ml-3" click={() => {
                                 const item = {
                                    id: thisProduct.id,
                                    subId: kgId,
                                    name: thisProduct.name,
                                    price: currPrice,
                                    kg: ikg + 1,
                                    kgName: kgName,
                                    quantity: quantity,
                                    image: imgUrl + thisProduct.image,
                                    cakeImage: uploadedImage,
                                    message: noscript(message),
                                    originalPrice: originalPrice,
                                    selectedDate: selectedDate,
                                    selectedSlot: selectedSlot
                                 }
                                 buyNowHandle(item)
                              }} bgHover="darkGreen" text="Buy Now" bgColor="green" textColor="white" /> */}
                           </div>

                        </div>

                  }
               </div>
               {thisProduct && <ProductDetails productContent={thisProduct} />}

            </div>
         </div>

      </>
   )
}

export default ProductView