import { StarIcon, UserIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import Button from '../button/button'
import StarRating from './StartRating'

function MyReviews({ myReviews }) {

  const [reviews, setReviews] = useState([])

  useEffect(() => {
    setReviews(myReviews);
  }, [])

  return (
    <div>
      <div className="text-2xl mb-5">My Reviews</div>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-6">

        {reviews?.map((review) => (
          <div className="bg-white border p-6 rounded-2xl">
            <h6 className="text-black text-lg leading-6 mb-4">{review.review_title}</h6>
            <div className="text-orange text-md flex products-center">{review.order_rating}.0 <StarIcon className="h-5 w-5 ml-1" /></div>
            {review.review && <p className="text-sm opacity-50 mb-4">"{review.review}"</p>}
            <div className="flex items-center">
              {/* <UserIcon className="w-10 h-10 mr-4 text-gray-400 bg-gray-100 rounded-full p-2" /> */}
              <div className="text-md text-brown">{review.product_name}<span className="block text-sm text-gray-400">{review.date}</span></div>
            </div>
          </div>
        ))}




        {/* <div className="bg-white border p-6 rounded-2xl">
          <h6 className="text-black text-lg leading-6 mb-4">Order was delivered in good condition. Great quality.</h6>
          <p className="text-sm opacity-50 mb-4">"My girlfriend had received the order on time. She felt too special to have that mouth watering cake on her birthday. Thanks Mayavi"</p>
          <div className="flex items-center">
            <UserIcon className="w-10 h-10 mr-4 text-gray-400 bg-gray-100 rounded-full p-2" />
            <div className="text-md text-brown">Muhammed Shafi<span className="block text-sm text-gray-400">14-06-2021</span></div>
          </div>
        </div> */}

      </div>
      {/* <div className="bg-gray-50 p-10 rounded-xl m-auto mt-10">
        <h1 className="text-2xl mb-4">Add Review</h1>
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-sm text-brown mb-2">Your Rating</label>
            <StarRating />
          </div>
          <div>
            <label className="block text-sm text-brown mb-2">Write a headline for your review here</label>
            <input className="border w-full h-12 rounded outline-none px-3" type="text" name="" id="" />
          </div>
          <div>
            <label className="block text-sm text-brown mb-2">Write your review here</label>
            <textarea className="border w-full h-24 rounded outline-none px-3 resize-none" type="text" name="" id=""></textarea>
          </div>
          <div>
            <Button link="/" bgColor="red" textColor="white" text="Submit" bgHover="darkRed" />
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default MyReviews
