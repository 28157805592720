import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../../context/Provider'
import Category from '../../products/category/CategoryCard'

function CategoryHome() {
   const { categoriesState, categoriesDispatch } = useContext(GlobalContext)

   return (
      <div className="bg-lightRed sm:mx-6 rounded-3xl py-16">
         <div className="container">
            <div className="sm:flex sm:justify-between mb-10">
               <h5 className="md:text-3xl sm:text-lg text-2xl text-brown">Explore Categories</h5>
               <Link to={`/category`} className="mt-4 sm:mt-0 block text-red underline hover:text-darkRed hover:no-underline md:text-xl sm:text-sm" href="#">View All</Link>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 sm:gap-8">
               <Category categoriesState={categoriesState}/>
            </div>

         </div>
      </div>
   )
}

export default CategoryHome