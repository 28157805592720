import React from 'react'
import Slider from "react-slick";
import { StarIcon,ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const testimonials = [
    {
        name: "Muahmmed Afsal",
        description: "It was so soft and tasty. I love them making a fresh cake to give it more tasteful.",
        rating: "4.9"
    },
    {
        name: "Afsal Ap",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        rating: "4.7"
    },
    {
        name: "Shakir Sha",
        description: "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        rating: "4.6"
    }
]

function Testimonials() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return(
        <div className="testmonial bg-lightOrange sm:mx-6 rounded-3xl pt-20 m-pt-40 m-pb-40">
          <div className="container"> 
             <div className="sm:grid sm:grid-cols-2">
               <div className="pb-44 px-4 sm:px-0 m-pb-80">
                  <h5 className="md:text-3xl sm:text-xl text-brown mb-10 sm:mb-20">What our customers say</h5>  
                  <Slider {...settings}>
                      {testimonials.map((item) => (
                      <div>
                          <p className="text-black text-2xl sm:text-3xl mb-4">“ {item.description} “</p>
                          <div className="flex items-center">
                             <div className="text-brown text-lg mr-4">{item.name}</div>
                             <div className="bg-orange pr-3 pl-2 py-1 rounded-full text-white flex items-center">
                                <StarIcon className="mr-2 w-5 h-5"/>{item.rating}
                             </div>
                          </div>
                      </div>
                     ))}
                  </Slider>              
               </div>  
               <div className="relative m-none">
                  <img className="sm:absolute bottom-0" src={process.env.PUBLIC_URL + '/images/testimonial.png'} alt=""/>  
               </div>
             </div>
          </div>
        </div>
    )
}
export default Testimonials 

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="w-14 h-14 rounded-full bg-white flex items-center justify-center text-gray-400 hover:text-black cursor-pointer shadow-xl absolute -bottom-20"><ChevronLeftIcon className="w-8 h-8"/></div>
    );
  }
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className="w-14 h-14 rounded-full bg-white flex items-center justify-center text-gray-400 hover:text-black cursor-pointer shadow-xl absolute -bottom-20 left-20"><ChevronRightIcon className="w-8 h-8"/></div>
    );
} 