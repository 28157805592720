import React from "react"
import { Link, NavLink } from 'react-router-dom'
import FeatherIcons from 'feather-icons-react'
import { facebookLink, instagramLink, linkedinLink, pinterestLink, twitterLink } from "../../constants/config"

function Footer(user) {
   return (
      <div className="footer">
         <div className="pt-20 py-4 m-none">
            <div className="container">
               <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3 sm:col-auto"><img className="lg:h-24 w-auto" src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="Mayavi Logo" /></div>
                  <div className="col-span-2">
                     <h6 className="text-black text-sm sm:text-xl mb-4">Know Us</h6>
                     <div className="grid lg:grid-cols-2">
                        <Link className="text-brown mt-2 text-sm block hover:text-black" to="/about">About Us</Link>
                        <Link className="text-brown mt-2 text-sm block hover:text-black" to="/">Customised Cakes</Link>
                        <Link className="text-brown mt-2 text-sm block hover:text-black" to="/help">Contact Us</Link>
                        <Link className="text-brown mt-2 text-sm block hover:text-black" to="/faq">FAQ</Link>
                        <Link className="text-brown mt-2 text-sm block hover:text-black" to="/refund">Cancellation and Refund</Link>
                        <Link className="text-brown mt-2 text-sm block hover:text-black" to="/privacy">Privacy Policy</Link>
                        <Link className="text-brown mt-2 text-sm block hover:text-black" to="/terms">Terms and Conditions</Link>
                     </div>
                  </div>
                  <div className="col-span-2 sm:col-auto">
                     <h6 className="text-black text-sm sm:text-xl mb-4">Find Us</h6>
                     <div className="flex items-center">
                        <a className="opacity-50 hover:opacity-100" href={facebookLink} target="_blank"><img className="lg:h-8 md:h-6 w-auto" src={process.env.PUBLIC_URL + '/images/fb.svg'} alt="Facebook" /></a>
                        <a className="opacity-50 hover:opacity-100 ml-3" href={twitterLink} target="_blank"><img className="lg:h-8 md:h-6 w-auto" src={process.env.PUBLIC_URL + '/images/tw.svg'} alt="Twitter" /></a>
                        <a className="opacity-50 hover:opacity-100 ml-3" href={instagramLink} target="_blank"><img className="lg:h-8 md:h-6 w-auto" src={process.env.PUBLIC_URL + '/images/in.svg'} alt="Instagram" /></a>
                        <a className="opacity-50 hover:opacity-100 ml-3" href={pinterestLink} target="_blank"><img className="lg:h-8 md:h-6 w-auto" src={process.env.PUBLIC_URL + '/images/pinterest.svg'} alt="Pinterest" /></a>
                        <a className="opacity-50 hover:opacity-100 ml-3" href={linkedinLink} target="_blank"><img className="lg:h-8 md:h-6 w-auto" src={process.env.PUBLIC_URL + '/images/link.svg'} alt="Linkedin" /></a>
                     </div>
                  </div>
               </div>
               <div className="sm:grid sm:grid-cols-2 mt-14 sm:flex sm:items-center text-center sm:text-left">
                  <p className="text-sm text-brown mb-3 sm:mb-0">&copy; 2022 All rights reserved. Developed by <a rel="noopener noreferrer" href="https://www.cookee.io/" target="_blank">Cookee</a></p>
                  <img src={process.env.PUBLIC_URL + '/images/payment.jpg'} alt="#" />
               </div>
            </div>
         </div>

         <div className="m-footer block sm:hidden">
            <div className="container-fluid">
               <div className="grid grid-cols-2 gap-4">

                  <NavLink exact to="/" activeClassName="active" className="nav-item">
                     <FeatherIcons className="ft-ico" strokeWidth="1.5" size="14" icon="home" />
                     <h6 className="text-black text-sm sm:text-xl">Home</h6>
                  </NavLink>
                  <NavLink to="/category" activeClassName="active" className="nav-item">
                     <FeatherIcons className="ft-ico" strokeWidth="1.5" size="14" icon="grid" />
                     <h6 className="text-black text-sm sm:text-xl">Categories</h6>
                  </NavLink>
                  {/*  <NavLink to="/cart" activeClassName="active" className="nav-item">
                     <FeatherIcons className="ft-ico" strokeWidth="1.5" size="14" icon="shopping-bag" />
                     <h6 className="text-black text-sm sm:text-xl">Cart</h6>
                  </NavLink> */}

                  {/*  <NavLink to={user ? "/myaccount" : "/login"} activeClassName="active" className="nav-item">
                     <FeatherIcons className="ft-ico" strokeWidth="1.5" size="14" icon="user" />
                     <h6 className="text-black text-sm sm:text-xl">Account</h6>
                  </NavLink>
 */}
               </div>
            </div>
         </div>
      </div>
   )
}

export default Footer