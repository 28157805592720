import React from 'react'

function DownloadApp() {
    return (
        <div className="border-b m-none">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Unlock Exclusive Offers</span>
          <span className="block text-orange">Download the Mayavi app</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex">
          <a className="w-40 block mr-3" href="#"><img className="max-w-full" src={process.env.PUBLIC_URL + '/images/play_store@2x.png'} alt="Mayavi Android App"/></a>
               
          </div>
          <div className="ml-3 inline-flex">
             <a className="w-40 block" href="#"><img className="max-w-full" src={process.env.PUBLIC_URL + '/images/app_store@2x.png'} alt="Mayavi Android App"/></a>
          </div>
        </div>
      </div>
    </div>
    )
}

export default DownloadApp
