import { POPULAR_PRODUCTS_LOADING, POPULAR_PRODUCTS_LOAD_ERROR, POPULAR_PRODUCTS_LOAD_SUCCESS } from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstance from "../../helpers/axiosInstance"


export default (history) => (dispatch) => {
    dispatch({
        type: POPULAR_PRODUCTS_LOADING,
    });
    axiosInstance(history)
        .get('/api/v1/app/get_products', {
            params: {
                popular: 1
            }
        })
        .then(res => {
            // console.log('respopular :>> ', res);
            dispatch({
                type: POPULAR_PRODUCTS_LOAD_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            // console.log('poperr :>> ', err);
            dispatch({
                type: POPULAR_PRODUCTS_LOAD_ERROR,
                payload: err.response ? err.response.data : CONNECTION_ERROR,
            });
        })
}


