import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import Button from "../components/button/button";
import PageBanner from "../components/common/PageBanner";
import axiosInstance from "../helpers/axiosInstance";
import CryptoJS from 'crypto-js'
import { GlobalContext } from "../context/Provider";

function Login(props) {

    const [redirect, setRedirect] = useState("")
    const { location } = props;

    useEffect(() => {
        if (location.state) {
            setRedirect(location.state?.from)
        }
    }, [])

    const history = useHistory()
    const { setUser } = useContext(GlobalContext)

    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [inputName, setInputName] = useState("")
    const [registerStatus, setRegisterStatus] = useState(false)
    const [otpError, setOtpError] = useState("")
    const [otp, setOtp] = useState(new Array(4).fill(""))
    const [resStatus, setResStatus] = useState("")
    const [useId, setUserId] = useState("")
    const [userName, setUserName] = useState("")
    const [resMessage, setResMessage] = useState("")
    const [verifyLoader, setVerifyLoader] = useState(false)
    const [loginLoader, setLoginLoader] = useState(false)


    const otpTime = 1;
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });



    function loginHandle(e) {
        e.preventDefault();
        if (phone.length == 10) {
            if (!loginLoader) {
                setLoginLoader(true)
                axiosInstance(history)
                    .post('/api/v1/app/login_check_phone', {
                        phone: phone
                    })
                    .then(res => {
                        // console.log('res :>> ', res);
                        if (res.data.status) {
                            setLoginLoader(false)
                            res.data.user_id ? setUserId(res.data.user_id) : setUserId("");
                            res.data.user_name ? setUserName(res.data.user_name) : setUserName("");
                            res.data.register_status == "login" ? setRegisterStatus(false) : setRegisterStatus(true);
                            setResStatus(res.data.status)
                            setMinutes(otpTime)
                            // console.log('res.data.otp :>> ', res.data.otp);
                        }
                    })
                    .catch((err) => {
                        // console.log('err :>> ', err);
                    })
            }
        } else {
            setPhoneError("Please enter a valid number")
        }
    }

    const verifyOtp = (e) => {
        e?.preventDefault();
        if (otp.join("").length == 4) {
            if (registerStatus) {
                if (inputName !== "") {
                    setVerifyLoader(true)
                    axiosInstance(history)
                        .post('/api/v1/app/login_verify_otp', {
                            user_id: useId,
                            otp: otp.join(""),
                            name: inputName,
                        })
                        .then(res => {
                            setResMessage(res.data.message)
                            setOtpError("")
                            if (res.data.status) {
                                setVerifyLoader(false)
                                if (res.data.access_tokken) {
                                    setMinutes(0)
                                    let data = { userName: res.data.user_name, userId: res.data.user_id, userMobile: res.data.user_phone, userEmail: res.data.user_email, userDob: res.data.user_dob, userGender: res.data.user_gender, userToken: res.data.access_tokken }
                                    var encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key@123').toString();
                                    localStorage.setItem('user', encryptedUser)
                                    setUser(data)
                                    history.push(redirect)
                                }

                            }
                        })
                        .catch((err) => {
                            // console.log('err :>> ', err);
                        })
                } else {
                    setOtpError("Please Enter Name")
                }
            } else {
                setVerifyLoader(true)
                axiosInstance(history)
                    .post('/api/v1/app/login_verify_otp', {
                        user_id: useId,
                        otp: otp.join(""),
                        name: userName,
                    })
                    .then(res => {
                        setVerifyLoader(false)
                        // console.log('res :>> ', res.data);
                        setResMessage(res.data.message)
                        if (res.data.status) {
                            if (res.data.access_tokken) {
                                setMinutes(0)
                                let data = { userName: res.data.user_name, userId: res.data.user_id, userMobile: res.data.user_phone, userEmail: res.data.user_email, userDob: res.data.user_dob, userGender: res.data.user_gender, userToken: res.data.access_tokken }
                                var encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key@123').toString();
                                localStorage.setItem('user', encryptedUser)
                                setUser(data)
                                history.push(redirect)
                            }

                        }
                    })
                    .catch((err) => {
                        console.log('err :>> ', err);
                    })
            }

        } else {
            // setOtpError("Enter Otp")
        }

    }


    useEffect(() => {
        if (otp.join("").length == 4)
            verifyOtp()
    }, [otp])

    const handleOtp = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index) ? element.value : d)])
        if (element.value) {
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        } else {
            if (element.previousSibling) {
                element.previousSibling.focus()
            }
        }
    }

    return <div className="border-b pb-20 m-border-0">
        <div className="container">
            <PageBanner title="Login" />
            <div className="bg-gray-50 p-10 sm:w-6/12 md:w-5/12 m-auto rounded-xl">
                {
                    resStatus ?
                        userName ?
                            <h1 className="text-2xl mb-4"> Welcome back <span className="text-orange">{userName}</span> </h1> :
                            <h1 className="text-2xl mb-4"> Register Now</h1> :
                        <h1 className="text-2xl mb-4 m-none">Login Now</h1>
                }

                {
                    resStatus && !userName ? <>
                        <label className="block text-sm text-brown mb-2">Name</label>
                        <input className="border w-full h-12 rounded mb-4 outline-none px-3" defaultValue={userName ? userName : ""} onChange={(e) => {
                            setInputName(e.target.value)
                        }} type="text" name="" id="" />
                    </> : ""}
                <label className="block text-sm text-brown mb-2">Phone Number</label>
                <input className="border w-full h-12 rounded mb-4 outline-none px-3" inputMode='numeric' pattern="[0-9]*" onChange={(e) => {
                    setPhone(e.target.value)
                    if (e.target.value.length == 10) {
                        setPhoneError("")
                    } else if (e.target.value.length > 10) {
                        setPhoneError("Please enter a valid number")
                    }
                }} type="text" disabled={resStatus ? "true" : ""} name="" id="" />
                {phoneError &&
                    <span className="block text-sm text-red mb-2">{phoneError}</span>
                }


                {resStatus ? <>
                    {minutes === 0 && seconds === 0
                        ? <label className="block text-sm underline text-brown mb-2 cursor-pointer" onClick={loginHandle} >Resend otp</label>
                        : <label className="block text-sm text-black mb-2">Verify <span className="text-brown ml-2"> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span></label>
                    }

                    <div className="flex items-center xl:w-6/12 mb-8">
                        {otp.map((digit, index) => {
                            return (
                                <input key={index} inputMode='numeric' pattern="[0-9]*" className={`text-center border w-full h-12 rounded ${index === 3 ? "" : "mr-3"} outline-none`} maxLength="1" type="text"
                                    value={digit}
                                    onChange={(e => handleOtp(e.target, index))}
                                    onFocus={e => e.target.select()}
                                    name="" id="" />
                            )
                        })}
                    </div>
                </> : ""}

                {resMessage &&
                    <span className="block text-sm text-red mb-2">{resMessage}</span>
                }
                {
                    resStatus && otpError &&
                    <span className="block text-sm text-red mb-2">{otpError}</span>
                }

                {
                    resStatus ? ""
                        : <Button click={(e) => { loginHandle(e) }} bgColor="red" textColor="white" text="Login Now" bgHover="darkRed" loader={loginLoader} disabled={loginLoader} />
                }

                {
                    resStatus && registerStatus ? <Button click={(e) => { verifyOtp(e) }} bgColor="red" textColor="white" text="Register" bgHover="darkRed" loader={verifyLoader} />
                        : ""
                }

            </div>
        </div >
    </div >
}

export default withRouter(Login);
