import React, { useState, Fragment, useEffect, useContext } from 'react'
import Button from '../button/button'
import { Dialog, Transition } from '@headlessui/react'
import StarRating from './StartRating'
import { useHistory } from 'react-router-dom';
import axiosInstanceProfile from '../../helpers/axiosInstanceProfile';
import { StarIcon } from '@heroicons/react/solid';
import getProfileData from '../../context/actions/getProfileData';
import { GlobalContext } from '../../context/Provider';
import toast, { Toaster } from 'react-hot-toast';

function Orders({ orders, imgUrl, resProfileLoad }) {

  const history = useHistory();
  const dateOptions = { month: 'long', day: 'numeric' };
  const timeOptions = { hour: 'numeric', hour12: true };
  const { profileDataState, profileDataDispatch } = useContext(GlobalContext)


  const [order, setOrder] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    setOrder(orders)
    setImageUrl(imgUrl);
  }, [profileDataState])

  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [orderId, setOrderId] = useState(0);
  const [productId, setProductId] = useState(0);

  function handleSubmit() {
    if (rating > 0) {
      let insData = {
        order_id: orderId,
        product_id: productId,
        order_rating: rating,
        review: description ? description : "",
        review_title: title ? title : "",
      }
      axiosInstanceProfile(history).post('/api/v1/app/insert_review_product',
        insData
      )
        .then((res) => {
          if (res?.data?.status && res?.data?.action_status) {
            getProfileData(history)(profileDataDispatch)
            closeModal()
          }
        })
        .catch(err => console.log(err));
    } else {
      toast.error("Please Rate Your Experience")
    }

  }


  function showModal(order, product) {
    setOrderId(order);
    setProductId(product);
    openModal()
  }


  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
    setRating(0)
    setTitle("")
    setDescription("")
  }

  function openModal() {
    setIsOpen(true)
  }


  return (
    <div className="mst">
      <Toaster />
      <div className="text-2xl mb-5">Orders</div>
      {
        resProfileLoad ?
          <div className="grid lg:grid-cols-1 gap-0 items-center border p-5 mb-10 rounded">
            <div className='col-span-1'>
              <div className="text-lg text-gray-400 pb-5"></div>
            </div>
            <div className="lg:pl-10 py-6">
              <span className="text-white bg-green bg-gray-200 animate-pulse h-4 w-2/4 inline-block px-2 py-1 rounded inline-block text-sm mb-3"></span>
              <br />
              <div className="text-sm text-gray-400 mt-4 bg-gray-200 animate-pulse h-4 w-4 inline-block"> <span className="text-black block text-1xl"></span></div>
              <hr className="mt-5" />
              <div className="text-sm text-gray-400 mt-4"> <span className="text-black block text-1xl float-right"></span></div>

              <hr className="mt-5" />
              <div className="text-sm text-gray-400 mt-4"> <span className="text-black block text-1xl float-right"></span></div>
            </div>
          </div>
          :
          order?.length > 0 ?
            order?.map((ord, i) => (
              <div key={i} className="grid lg:grid-cols-1 gap-4 items-center border p-5 mb-10 rounded">
                <div className='col-span-1 overflow-hidden'>

                  <div className="flex items-center justify-between">
                  <div className="text-lg text-gray-400 pb-5">Order Id: {ord.id}</div>
                  <span className={`text-white bg-green px-2 py-1 rounded inline-block text-sm mb-5 status-${ord.status}`}>Status : {ord.status}</span>
                  </div>
                  
                  
                  {
                    ord?.products?.map((pro, j) => (
                      <div key={j} className="item">
                        <div className="mnt"><img className="rounded" src={imageUrl + pro.image} alt={pro.product_name} />
                        <h6>{pro.product_name}</h6></div>
                        <div className="text-sm text-gray-400 mr-10">Weight <span className="block text-black">{pro.sub_product_name}</span></div>
                        <div className="text-sm text-gray-400 mr-10">Quantity <span className="block text-black">{pro.count}</span></div>
                        <div className="text-sm text-gray-400 mr-10">Price <span className="block text-black">₹{pro.price}</span></div>
                        {
                          pro.order_rating ? <div className="text-orange text-md flex products-center mbtn">{pro.order_rating}.0 <StarIcon className="h-5 w-5 ml-1" /></div>
                            : ord.status == "Deliverd" &&  <Button click={() => { showModal(ord.id, pro.id) }} bgHover="black" text="Add Review" bgColor="gray-300" textHover="white" size="sm" width="w-200" padding="py-2 px-4" />

                        }
                      </div>
                    ))
                  }
                </div>
                <div className="lg:pl-1 inline-block md:flex items-center">
                  
                  <div className="gd w-full md:w-1/2 pb-7 md:pb-0">
                  <div className="text-sm text-gray-400">Delivery Expected By <span className="text-black block text-1xl">{new Date(ord.delivery_date).toLocaleDateString(undefined, dateOptions)} - {ord.delivery_date_slot}</span></div>
                  </div>

                  <div className="gd w-full md:w-1/2">
                
                  <hr />
                  <div className="text-sm text-gray-400 mt-4">Subtotal <span className="text-black block text-1xl float-right">₹{ord.total}</span></div>
                  {/* <div className="text-sm text-gray-400 mt-4">Delivery Charge <span className="text-black block text-1xl float-right">₹10</span></div> */}
                  {
                    ord.promocode_discount > 0 && <div className="text-sm text-gray-400 mt-4">Promocode Discount <span className="text-black block text-1xl float-right">₹{ord.promocode_discount}</span></div>
                  }

                  <hr className="mt-5" />
                  <div className="text-sm text-gray-400 mt-4">Total <span className="text-black block text-1xl float-right">₹{ord.total_pay}</span></div>
                
                  </div>
                </div>

              </div>
            ))
             :
            "No Orders"

      }

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">

            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-medium leading-6 text-gray-900 mb-4"
                >
                  Add Review
                </Dialog.Title>
                <div className="mt-2 grid grid-cols-1 gap-6">
                  <div>
                    <label className="block text-sm text-brown mb-1">Your Rating</label>
                    <StarRating rating={rating} setRating={setRating} />
                  </div>
                  <div>
                    <label className="block text-sm text-brown mb-2">Write a headline for your review here</label>
                    <input className="border w-full h-12 rounded outline-none px-3" type="text" value={title} onChange={(e) => setTitle(e.target.value)} name="" id="" />
                  </div>
                  <div>
                    <label className="block text-sm text-brown mb-2">Write your review here</label>
                    <textarea className="border w-full h-24 rounded outline-none px-3 resize-none" value={description} onChange={(e) => setDescription(e.target.value)} type="text" name="" id=""></textarea>
                  </div>
                  <div>
                    <Button click={handleSubmit} bgColor="red" textColor="white" text="Submit" bgHover="darkRed" />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

    </div>
  )
}

export default Orders
