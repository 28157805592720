import axios from "axios";
import CryptoJS from 'crypto-js'

export default (history = null) => {
    const baseURL = process.env.REACT_APP_BACKEND_URL;

    let headers = {
        "tokenvalid": "Token FxxYzaCbZcymKixJw5FQp0yTuWXlvQVIaRd549TJvdXTRkPyuUpkYF8VTRKqTmvdYS8VAFRfPillZtrj3gDutBWnrSF4wvpWINV31qctkIDpF2MTUcgtESC2w2xiiekVHP60Vt0aS7QbTwfK5LdxLSJwXhLwcB7h0sS4GoMpSrUaZ5b39MAog459oR7pJC1lessnSDAVM1QDysXPwfdItTLeiopbS6rObGKI5r8skxMkzoOqrk5o0xqeoBYTUbG",
    };

    if (localStorage.getItem("user")) {
        let EncryptedAuthUser = localStorage.getItem("user");
        if (EncryptedAuthUser) {
            var bytes = CryptoJS.AES.decrypt(EncryptedAuthUser, 'secret-key@123');
            var AuthUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            headers.accesstoken = `Token ${AuthUser?.userToken}`;
            headers.userid = AuthUser?.userId;
        }
        // console.log('AuthUser :>> ', AuthUser?.userToken);
    }

    const axiosInstance = axios.create({
        baseURL,
        headers
    });


    axiosInstance.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            if (error.response.status === 403) {
                console.log("403 Error");
                // localStorage.removeItem("token");

                if (history) {
                    history.push('/')
                } else {
                    window.location = "/"
                }

            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        }
    )
    return axiosInstance;
};
