import { CATEGORIES_LOADING, CATEGORIES_LOAD_ERROR, CATEGORIES_LOAD_SUCCESS } from "../../constants/actionTypes";

const categories = (state, {payload,type}) => {
    switch (type) {
        case CATEGORIES_LOADING: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    loading: true,
                },
            }
        }
        case CATEGORIES_LOAD_SUCCESS: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    loading: false,
                    data:payload,
                },
            }
        }
        case CATEGORIES_LOAD_ERROR: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    loading: false,
                    error:payload
                },
            }
        }
        default:
            return state;
    }
}


export default categories;