import { PROMO_CODE_DATA_ERROR, PROMO_CODE_DATA_LOADING, PROMO_CODE_DATA_LOAD_SUCCESS } from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstanceProfile from "../../helpers/axiosInstanceProfile";


export default (history) => (dispatch) => {
    dispatch({
        type: PROMO_CODE_DATA_LOADING,
    });
    axiosInstanceProfile(history)
        .get('/api/v1/app/get_promo_codes')
        .then(res => {
            // console.log('PROMO_CODE :>> ', res);
            dispatch({
                type: PROMO_CODE_DATA_LOAD_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            // console.log('PROMO_CODE Err :>> ', err);
            dispatch({
                type: PROMO_CODE_DATA_ERROR,
                payload: err.response ? err.response.data : CONNECTION_ERROR,
            });
        })
}


