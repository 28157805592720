import { CashIcon, CheckIcon, TrashIcon, CreditCardIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Button from '../components/button/button'
import PageBanner from '../components/common/PageBanner'
import { GlobalContext } from '../context/Provider';
import { useForm } from "react-hook-form";
import insertOrder from '../context/actions/insertOrder';
import productsData from '../data/product_data';
import clearInsertOrder from '../context/actions/clearInsertOrder';
import toast, { Toaster } from 'react-hot-toast';
import CryptoJS from 'crypto-js'
import axiosInstanceProfile from '../helpers/axiosInstanceProfile';

function CheckOut({ isAuth }) {

  const history = useHistory()

  var paymentError = false;
  var failed_transaction_id = "";

  //PromoCode
  const [validPromoCode, setValidPromoCode] = useState({});
  const [apiProcessing, setApiProcessing] = useState(false);

  const { userName, userMobile, userEmail } = isAuth
  const { cart, setCart } = useContext(GlobalContext)

  const { promoCodeDataState: { promoCode: { data: resPromoCode, error: resPromoCodeError, loading: resPromoCodeLoad } }, promoCodeDataDispatch } = useContext(GlobalContext)

  const { register, formState: { errors }, handleSubmit } = useForm();

  const [checked, setChecked] = useState("");
  const toggleChecked = (val) => setChecked(val);
  const CheckBox = [
    { name: 'Cash On Delivery', icon: CashIcon, value: 'delivery' },
    { name: 'Online Payment', icon: CreditCardIcon, value: 'payment' },
  ]

  const [locations, setLocations] = useState([]);
  const { locationsState: {
    locations: { data: loc, error: locError, loading: locLoad }
  } } = useContext(GlobalContext)
  useEffect(() => {
    setLocations(loc?.locations)
    if (cart?.items.length === 0) {
      history.push("cart")
    }
  }, [])
  const locSelected = JSON.parse(localStorage.getItem('location'))
  const [dropSelectedLoc, setDropSelectedLoc] = useState(locSelected.id || "")

  const getCartSubTotal = (items) => (items?.reduce((total, item) => total + parseInt(item.price), 0))
  const subTotal = getCartSubTotal(cart.items);

  const setToLocalStorage = (items, subTotal, promo) => {
    let cartCopy = {
      'items': items,
      'subTotal': subTotal,
      'promoCode': promo ?? {}, //res?.promo_code 
    }
    setCart(cartCopy);
    let stringCart = JSON.stringify(cartCopy);
    var encryptedCart = CryptoJS.AES.encrypt(stringCart, 'secret-key@123').toString();
    localStorage.setItem("cart", encryptedCart)
  }


  const { insertOrderState: { order: { data: resOrder, error: resOrderError, loading: resOrderLoad } }, insertOrderDispatch } = useContext(GlobalContext)
  // console.log('resOrder :>> ', resOrder);

  useEffect(() => {
    if (resOrder?.status) {
      if (resOrder?.action_status) {
        localStorage.removeItem('cart')
        setCart({
          items: [],
          subTotal: 0,
          promoCode: {}
        });
        if (resOrder?.payment_status == "Payment Failed") {
          history.push('/failed')
          // toast.error("Order Failed")
        } else {
          // toast.success("Order Success")
          history.push('/success')
        }
      } else {
        toast.error('Order Failed.')
      }
    }

    return () => {
      clearInsertOrder()(insertOrderDispatch)
    }

  }, [resOrder])

  const validatePromoCode = async (code, total) => {
    try {
      if (code != "") {
        setApiProcessing(true)
        let res = await axiosInstanceProfile(history).post('/api/v1/app/check_promo_code', {
          'code': code,
          'total': total,
        })

        if (res.data.action_status) {
          let resPromo = res.data.promo_code;
          let promoCodeFinal = {
            id: resPromo.id,
            code: resPromo.code,
            discount: resPromo.discount,
            total: resPromo.total
          };
          setValidPromoCode(promoCodeFinal)
          return promoCodeFinal
        } else {
          setValidPromoCode({})
          return {}
        }
      } else {
        setValidPromoCode({})
        return {}
      }
    }
    catch (err) {
      //console.error(err);
    }

    // if (code != "") {
    //   setApiProcessing(true)
    //   let validCode = resPromoCode?.promocodes?.filter(function (promo) {
    //     return promo.code == code;
    //   })[0];
    //   if (validCode) {
    //     console.log('code :>> ', code, validCode, total);
    //     if (parseInt(validCode.min_amount) <= total) {
    //       var discount = 0;
    //       if (validCode.type == "Percentage") {
    //         discount = total * parseInt(validCode.amount) / 100;
    //       } else {
    //         discount = validCode.amount;
    //       }
    //       if (discount > parseInt(validCode.max_discount_amount)) {
    //         discount = validCode.max_discount_amount;
    //       }
    //       total -= discount;
    //       let promoCodeFinal = {
    //         id: validCode.id,
    //         code: validCode.code,
    //         discount: discount,
    //         total: total
    //       };
    //       setValidPromoCode(promoCodeFinal)
    //       return promoCodeFinal;
    //     } else {
    //       setValidPromoCode({})
    //       return {};
    //     }
    //   } else {
    //     setValidPromoCode({})
    //     return {};
    //   }
    // }
  }


  useEffect(() => {
    if (cart.items?.length > 0 && cart.promoCode?.code) {
      validatePromoCode(cart.promoCode?.code, subTotal)
        .then(code => {
          setToLocalStorage(cart.items, subTotal, code)
        })
        .finally(function () {
          setApiProcessing(false)
        });
    }
  }, [])


  const removeCartItem = (itemID, kg) => {
    let cartItemsCpy = [...cart.items]
    cartItemsCpy = cartItemsCpy.filter((item) => {
      if (item.id == itemID) {
        return item.kg !== kg
      } else {
        return item.id !== itemID
      }
    });

    // if(cart.promoCode?.code){
    //   setValidPromoCode(cart.promoCode?.code)
    //   validatePromoCode(cart.promoCode?.code)
    // }
    let finalSubTotal = getCartSubTotal(cartItemsCpy)

    if (cart.promoCode?.code) {
      validatePromoCode(cart.promoCode?.code, finalSubTotal)
        .then(code => {
          setToLocalStorage(cartItemsCpy, finalSubTotal, code)
        })
        .finally(function () {
          setApiProcessing(false)
        });
    } else {
      setToLocalStorage(cartItemsCpy, finalSubTotal, {})
    }

    if (cart.items.length <= 1) {
      history.push("/cart")
    }
  }

  let getItemsSlots = cart.items.map((pro) => {
    return { selectedDate: pro.selectedDate, selectedSlot: pro.selectedSlot }
  })

  let getItems = cart.items.map((pro) => {
    return { product_id: pro.id, product_sub_id: pro.subId, quantity: pro.quantity, cake_image: pro.cakeImage ? pro.cakeImage : "", note: pro.message ? pro.message : "" }
  })

  //get Max delivery Date
  let getMaxDateSlot = async () => {
    let items = await getItemsSlots;
    var maxDate = items.reduce(function (a, b) { return a.selectedDate > b.selectedDate ? a.selectedDate : b.selectedDate; });
    var maxSlot = items.reduce(function (a, b) {
      if (b.selectedDate == maxDate) {
        return a.selectedSlot > b.selectedSlot ? a.selectedSlot : b.selectedSlot;
      }
    });
    return {
      maxDate: maxDate,
      maxSlot: maxSlot
    }
  }

  const total_amount = cart.promoCode.total ?? cart.items.reduce((a, p) => a = a + p.price, 0)

  const _DEV = document.domain === 'localhost'

  //Payment


  const openPayModal = (data) => {
    const options = {
      key: _DEV ? "rzp_test_215VU1xs0mtsQu" : "rzp_live_icrX7dwVGeOixD",
      amount: total_amount * 100,
      name: 'Mayavi Cakes',
      description: 'You can contact Mayavi at any time to place an order for cakes for any happy event, such as birthdays, anniversaries, triumph celebrations, weddings, housewarming, career achievement, and so on.',
      image: 'http://mayavicakes.com/dashboard/assets/admin/img/logo.svg',
      handler: function (response) {
        if (response.razorpay_payment_id) {
          paymentError = false
          data.transaction_id = response.razorpay_payment_id
          data.payment_status = 'Success'
          insertOrder(data)(insertOrderDispatch)
        }
      },
      modal: {
        ondismiss: function () {
          if (paymentError) {
            data.status = 'Order Failed'
            data.payment_status = 'Payment Failed'
            data.transaction_id = failed_transaction_id
            insertOrder(data)(insertOrderDispatch)
          }
          // console.log('Checkout form closed');
        }
      },
      prefill: {
        name: data.shipping_name,
        contact: data.shipping_phone,
        email: data.shipping_email,
      },
      notes: {
        address: data.shipping_address
      },
      theme: {
        color: 'red',
        hide_topbar: false
      }
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', function (response) {
      if (response.error.code) {
        paymentError = true
        failed_transaction_id = response.error.metadata.payment_id;
        // data.status = 'Order Failed'
        // data.payment_status = 'Payment Failed'
        // data.transaction_id = response.error.metadata.payment_id
        // insertOrder(data)(insertOrderDispatch)
      }
      // console.log(response.error.code);
      // console.log(response.error.description);
      // alert(response.error.metadata.order_id);
      // console.log(response.error.metadata.payment_id);
    });
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  //Payment

  const onSubmit = async (data) => {
    // toast.error("Check back after sometime")
    // console.log('data :>> ', data);
    let max = await getMaxDateSlot();
    let items = await getItems
    if (items) {
      data.selectedDate = max.maxDate
      data.selectedSlot = max.maxSlot
      data.promo_code_name = cart.promoCode?.code ?? ""
      data.items = items
      if (data.payment_method == "Cash On Delivery") {
        insertOrder(data)(insertOrderDispatch)
      } else {
        openPayModal(data)
        //history.push('/test')
        // toast.error("Online Payment ")
      }

    } else {
      toast.error("There is no Product found")
    }

  }


  return (
    <div className="border-b pb-20">
      <Toaster />
      <div className="container">
        <PageBanner title="Checkout" />
        {/* {
          resOrderLoad &&
          <div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{ top: "50%" }}>
              <i class="fas fa-circle-notch fa-spin fa-5x"></i>
            </span>
          </div>
        } */}

        <div className="grid md:grid-cols-4 lg:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <h1 className="text-black flex  items-center text-2xl mb-5">Add Delivery Address
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div>
                  <label className="block mb-2 text-brown">Recipient Name</label>
                  <input className="border w-full h-12 rounded" type="text" defaultValue={userName} name="" {...register("shipping_name", { required: true, maxLength: { value: 20, message: "Maximum Limit exceeds" } })} id="" />
                  {errors.shipping_name?.type === 'required' && <span style={{ color: "red" }}>Name is required</span>}
                </div>
                <div>
                  <label className="block mb-2 text-brown">Recipient Phone Number</label>
                  <input className="border w-full h-12 rounded" type="tel" defaultValue={userMobile} name="" {...register("shipping_phone", { required: "Phone Number Required", maxLength: { value: 10, message: "Please Enter Valid Phone Number" }, pattern: { value: /\d+/, message: "Please Enter Valid Number" } })} id="" />
                  {errors.shipping_phone && <span style={{ color: "red" }}>{errors.shipping_phone.message}</span>}
                </div>
                <div className="lg:col-span-2">
                  <label className="block mb-2 text-brown">Recipient Address</label>
                  <textarea className="border w-full h-16 rounded resize-none" type="text" {...register("shipping_address", { required: true })} id=""></textarea>
                  {errors.shipping_address?.type === 'required' && <span style={{ color: "red" }}>Address is required</span>}
                </div>
                <div>
                  <label className="block mb-2 text-brown">Location</label>
                  <select className="border w-full h-12 rounded" value={dropSelectedLoc}  {...register("shipping_location", { required: true })} onChange={(e) => setDropSelectedLoc(e.target.value)} id="">
                  <option value="" disabled selected>Select Location</option>
                    {locations?.map(location => (
                      <option key={location.id} value={location.id} >{location.name}</option>
                    ))}
                  </select>
                  {errors.shipping_location && <span style={{ color: "red" }}>Location is required</span>}
                </div>
                <div>
                  <label className="block mb-2 text-brown">Landmark</label>
                  <input className="border w-full h-12 rounded" type="text" name="" {...register("shipping_landmark", { required: true })} id="" />
                  {errors.shipping_landmark && <span style={{ color: "red" }}>Landmark is required</span>}
                </div>
                <div>
                  <label className="block mb-2 text-brown">Email ID</label>
                  <input className="border w-full h-12 rounded" type="email" defaultValue={userEmail} name="" {...register("shipping_email", { required: "Email Required", pattern: { value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Enter Valid Email" } })} id="" />
                  {errors.shipping_email && <span style={{ color: "red" }}>{errors.shipping_email.message}</span>}
                </div>
                <div className="lg:col-span-2">
                  <h1 className="text-black flex  items-center text-2xl mb-5">Payment Method</h1>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {
                      CheckBox.map((chk, index) => (
                        <div key={index} className="border flex items-center p-4 rounded-md relative">
                          {checked == chk.name ? <CheckIcon className="w-8 h-8 text-white bg-green rounded-full p-1 absolute right-4" /> : null}
                          <input checked={checked == chk.name} value={chk.name} {...register("payment_method", { required: true })} onClick={(e) => {
                            toggleChecked(e.target.value)
                          }} className="absolute top-0 right-0 left-0 bottom-0 z-10 w-full h-full opacity-0" type="radio" />
                          <label className="text-xl flex items-center"><chk.icon className="w-10 h-10 text-gray-400 mr-4" />{chk.name} </label>
                        </div>
                      ))
                    }
                    {errors.payment_method && <span style={{ color: "red" }}>Payment method is required</span>}
                    {/* <div className="border flex items-center p-4 rounded-md relative">
                      {checked && toggleChecked ? <CheckIcon className="w-8 h-8 text-white bg-green rounded-full p-1 absolute right-4" /> : null}
                      <input checked={checked} onChange={toggleChecked} className="absolute top-0 right-0 left-0 bottom-0 z-10 w-full h-full opacity-0" type="checkbox" name="payment" />
                      <label className="text-xl flex items-center"><CreditCardIcon className="w-10 h-10 text-gray-400 mr-4" />Online Payment </label>
                    </div> */}
                  </div>
                </div>

                <div className="lg:col-span-2 text-right">
                  <button disabled={resOrderLoad} className={`w-full lg:w-auto text-white hover:bg-darkRed, hover:text-white, py-3 px-8 rounded-lg inline-block cursor-pointer text-center ${resOrderLoad ? "bg-red cursor-not-allowed" : "bg-red"}`} type="submit" >Confirm &amp; Pay {resOrderLoad && <span class="ml-2">
                    <i class="fas fa-circle-notch fa-spin fa-1x"></i>
                  </span>}</button>
                  {/* <Button type="submit" width="w-full lg:w-auto" text="Confirm &amp; Pay" bgColor="red" bgHover="darkRed" textColor="white" /> */}
                </div>
              </div>
            </form>

          </div>
          <div className="bg-gray-50 rounded-2xl p-8 md:col-span-2 lg:col-auto">
            <div className="grid gap-6">
              <span className="block text-base mb-3">Order Summary</span>
              {cart.items?.map(product => (
                <div className="flex items-center relative">
                  <img className="rounded w-24 mr-3" src={product.image} alt={product.name} />
                  <h3 className="text-sm">{product.name.slice(0, 16) + '...'} <TrashIcon onClick={() => removeCartItem(product.id, product.kg)} className="w-6 h-6 text-center text-red p-1 bg-white rounded-full hover:bg-red hover:text-white transition duration-500 ease-in-out shadow cursor-pointer" /></h3>
                </div>
              ))}

              <div className="flex items-center justify-between border-b py-2">
                <span className="text-gray-400">Subtotal</span>
                <span className="text-brown">₹{subTotal}</span>
              </div>
              {/* <div className="flex items-center justify-between border-b py-2">
                <span className="text-gray-400">Tax 5%</span>
                <span className="text-brown">₹61</span>
              </div> */}
              <div className="flex items-center justify-between border-b py-2">
                <span className="text-gray-400">Delivery Charge</span>
                <span className="text-white bg-green text-sm px-2 rounded">FREE</span>
              </div>
              {
                cart.promoCode?.discount ? <div className="flex items-center justify-between border-b py-2">
                  <span className="text-gray-400">Promo Code Discount</span>
                  <span className="text-black">₹{cart.promoCode?.discount}</span>
                </div> : ''
              }

              <div className="flex items-center justify-between py-2">
                <span className="text-gray-400">Total</span>
                <span className="text-black">₹{cart.promoCode?.total ?? subTotal}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default withRouter(CheckOut)
