import React, { useEffect, useState } from "react";
// import createSlots from "../../../helpers/slotsGenerator";
// import Select from 'react-select'



function SelectSlot({ selectedDate,slotConfig, setSelectedSlot }) {
    const [selectionSlotsOptions, setSelectionSlotsOptions] = useState([])


    const createSlots = (slotConfig1) => {
        const {
            configSlotHours,
            configSlotMinutes,
            configSlotPreparation,
            timeArr
        } = slotConfig1;

        let defaultDate = new Date().toISOString().substring(0, 10);
        let slotsArray = [];
        let _timeArrStartTime;
        let _timeArrEndTime;
        let _tempSlotStartTime;
        let _endSlot;
        let _startSlot;

        for (var i = 0; i < timeArr.length; i++) {
            _timeArrStartTime = new Date((defaultDate + " " + timeArr[i].startTime).replace(/\s+/g, 'T')).getTime();
            _timeArrEndTime = new Date((defaultDate + " " + timeArr[i].endTime).replace(/\s+/g, 'T')).getTime();
            _tempSlotStartTime = _timeArrStartTime;

            while (
                new Date(_tempSlotStartTime).getTime() <
                new Date(_timeArrEndTime).getTime()
            ) {
                _endSlot = new Date(_tempSlotStartTime);
                _startSlot = new Date(_tempSlotStartTime);

                _tempSlotStartTime = _endSlot.setHours(
                    parseInt(_endSlot.getHours()) + parseInt(configSlotHours)
                );
                _tempSlotStartTime = _endSlot.setMinutes(
                    parseInt(_endSlot.getMinutes()) + parseInt(configSlotMinutes)
                );

                if (
                    new Date(_tempSlotStartTime).getTime() <=
                    new Date(_timeArrEndTime).getTime()
                ) {

                    slotsArray = [...slotsArray, {
                        value: _endSlot.toTimeString().split(" ")[0],
                        label: new Date(_startSlot).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true
                        }),
                    }];
                    // slotsArray.push({
                    //     value: _endSlot.toTimeString().split(" ")[0],
                    //     label: new Date(_startSlot).toLocaleTimeString("en-US", {
                    //         hour: "numeric",
                    //         minute: "numeric",
                    //         hour12: true
                    //     }),
                    // });
                }

                _tempSlotStartTime = _endSlot.setMinutes(
                    _endSlot.getMinutes() + parseInt(configSlotPreparation)
                );
            }
        }

        setSelectionSlotsOptions(slotsArray)

    }


    let slotsList = selectionSlotsOptions.length > 0
        && selectionSlotsOptions.map((item, i) => {
            return (
                <option key={i} value={item.value}>{item.label}</option>
            )
        }, []);

    useEffect(() => {
        createSlots(slotConfig)
        console.log('selectedDate :>> ', selectedDate);
        console.log('slotConfig :>> ', slotConfig);
        // setTimeout(function () { //Start the timer
        //     setSelectionSlotsOptions([
        //         { value: 'AFG', label: 'Afghanistan' },
        //         { value: 'AFG', label: 'Afghanistan' },
        //     ])
        // }, 10000)
    }, [selectedDate])


    return (

        selectionSlotsOptions.length > 0 ?
            <select className="outline-none w-full" onChange={(e) => { setSelectedSlot(e.target.value) }}>
                <option>Select Time Slot</option>
                {selectionSlotsOptions?.map((t, key) => (
                    <option key={key} value={t.value}>{t.label}</option>
                ))}
            </select>
            // <Select onChange={(e) => setSelectedSlot(e.target.value)} options={selectionSlotsOptions} />
            : <select className="outline-none w-full">
                <option>No Time Slots</option>
            </select>
    );
}

export default SelectSlot;
