import React, { Component } from 'react';
import { UserIcon } from '@heroicons/react/solid'
class ProductReviewList extends Component {
    render() {
        const {ProductReviewList} = this.props
        return (
            <>
           {ProductReviewList.reviewList ?
           <div className="grid grid-cols-1 mt-10 pt-10 border-t">
              <h3 className="text-3xl text-brown mb-5">Customer Reviews and Ratings for <span className="text-red">{ProductReviewList.title}</span></h3>    
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  {ProductReviewList.reviewList.map((child) => (
                   <div className="bg-white border p-6 rounded-2xl">
                     <h6 className="text-black text-lg leading-6 mb-4">{child.revTitle}</h6>  
                     <p className="text-sm opacity-50 mb-4">"{child.revContent}"</p>  
                     <div className="flex items-center">
                        <UserIcon className="w-10 h-10 mr-4 text-gray-400 bg-gray-100 rounded-full p-2"/>
                        <div className="text-md text-brown">{child.revauthorName}<span className="block text-sm text-gray-400">{child.revpostedDate}</span></div>
                     </div>  
                   </div> ))} 

              </div>
           </div> : "" }
            </>
        );
    }
}

export default ProductReviewList;