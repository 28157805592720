import { useEffect, useState } from "react"
export const LocationLocalStorage = localStorageKey => {
    const [value, setValue] = useState(
        JSON.parse(localStorage.getItem(localStorageKey)) || {}
    );

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
};