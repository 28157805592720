import React, { useEffect, useState } from "react";
import Button from '../../button/button';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

function OfferBanner({
    bannersState: {
        banners: { loading, error, data }
    }
}) {

    const offersDummy = [
        {
            description: "Enjoy 10% OFF! Use Coupon ‘Mayavi’",
            href: "/products",
            badge_text: "Offer",
            name: "Baked fresh for you.",
            button_text: "Grab offer"

        },
        {
            description: "Restaurants with best in class safety standards",
            href: "/products",
            badge_text: "Best Safety",
            name: "Customer Favourites",
            button_text: "Shop Now"

        }
    ]


    const [offers, setOffers] = useState([])
    useEffect(() => {
        if (data.banner) {
            setOffers(data.banner);
        }
    }, [data])

    const history = useHistory();
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // autoplay: true,
        // autoplaySpeed: 3000,
    };
    return <div className="offer-banner bg-slate-300">
    <div className="container">
        <div className="md:grid md:grid-cols-2 gap-4 h-96 md:h-60 lg:h-96 overflow-hidden">
            <div>
                {offers.length > 0 ?
                    <Slider className="sm:h-auto md:h-full md:flex md:items-center" {...settings}>
                        {offers?.map((item) => {
                            return (
                                <div key={item.id} className="w-full text-center md:text-left">
                                    <div className="flex items-center justify-center md:justify-start mb-4">
                                        <div className="bg-green text-white px-2 md:text-md sm:text-sm md:px-3 py-1 rounded-md uppercase">{item.badge_text}</div>
                                        <p className="ml-3 text-gray-400 md:text-md sm:text-sm">{item.name}</p>
                                    </div>
                                    <h1 className="lg:text-5xl md:text-3xl text-2xl mb-5 pr-0 md:pr-20">{item.description}</h1>
                                    <Button bgHover="darkRed" size="lg" bgColor="red" textColor="white" text={item.button_text} click={() => {
                                        if (item.category > 0) {
                                            history.push(`/category/${item.category}`)
                                        } else if (item.sub_category > 0) {
                                            history.push(`/products/${item.sub_category}`)
                                        } else if (item.product > 0) {
                                            history.push(`/products_view/${item.product}`)
                                        } else {
                                            history.push('/')
                                        }
                                    }} />
                                </div>
                            )
                        })
                        }
                    </Slider> :
                    <div className="sm:h-auto md:h-full md:flex md:items-center">
                        <div className="w-full text-center md:text-left">
                            <div className="flex items-center justify-center md:justify-start mb-4">
                                <div className="bg-green text-white px-2 md:text-md sm:text-sm md:px-3 py-1 rounded-md uppercase bg-gray-200 animate-pulse h-5 w-1/4"></div>
                                <p className="ml-3 text-gray-400 md:text-md sm:text-sm bg-gray-100 animate-pulse h-4 w-1/4"></p>
                            </div>
                            <h1 className="lg:text-5xl md:text-3xl text-2xl mb-2 pr-0 md:pr-20 rounded bg-gray-200 animate-pulse h-10 w-full"></h1>
                            <h1 className="lg:text-5xl md:text-3xl text-2xl mb-2 pr-0 md:pr-20 rounded bg-gray-200 animate-pulse h-10 w-3/4"></h1>
                            <h1 className="lg:text-5xl md:text-3xl text-2xl mb-5 pr-0 md:pr-20 rounded bg-gray-200 animate-pulse h-10 w-2/4"></h1>

                            <h1 className="py-3 px-8 rounded-lg inline-block text-center bg-darkRed animate-pulse w-1/4 h-10"></h1>
                        </div>
                    </div>

                }
            </div>
            <div className="relative w-50 mt-8 md:mt-0">
                <img className="w-full md:absolute animate-spin" src={process.env.PUBLIC_URL + '/images/banner.png'} alt="#" />
            </div>
        </div>
    </div>
    </div>
}

export default OfferBanner;

