import React from 'react'
import { HomeIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { useLocation } from 'react-router-dom'

function PageBanner({ title }) {
  const location = useLocation()
  return (
    <div className="rounded-2xl relative sm:h-48 text-center flex items-center justify-center lg:mb-20 xl:mb-32">
      <img className="w-100 absolute top-0 hidden sm:block" src={process.env.PUBLIC_URL + '/images/page_banner.jpg'} alt="" />
      <div className="relative z-10 mb-10 m-mb-20 sm:mb-0 sm:-mt-16 md:-mt-10 lg:mt-4 xl:mt-16">
        <h1 className="text-2xl sm:text-xl md:text-3xl m-fs-24">{title}</h1>
        <div className="sm:p-2 flex items-center justify-center m-none">

          <a className="flex items-center text-gray-400 text-sm" href="/"><HomeIcon className="w-4 h-4 mr-1 text-gray-300" />Home</a>
          {location.pathname ?
            <><ChevronRightIcon className="w-5 h-5 text-gray-300" />
              <a className="text-sm text-gray-400 capitalize" href="/products">{location.pathname.slice(1).split("/")[0]}</a></>
            : ""}
        </div>
      </div>
    </div>
  )
}

export default PageBanner
