import React from 'react'
import { UserIcon } from '@heroicons/react/solid'
import Button from '../button/button'
function Dashboard({ state, setState, user, userData, resProfileLoad }) {
  return (
    <div>
      <div className="flex items-center mb-8">
        <UserIcon className="w-24 h-24 bg-gray-100 text-gray-400 rounded-full p-5  mr-4" />
        <div>
          <div className="text-2xl">{userData?.name ? userData?.name : user?.userName}</div>
          {
            resProfileLoad ? <div className="text-gray-400 animate-pulse">Your email id</div> : <div className="text-base text-gray-400">{userData?.email ? userData?.email : (user?.userEmail ? user.userEmail : "your email id")}</div>
          }
        </div>
      </div>
      <div className="grid lg:grid-cols-3 border py-5 rounded mb-8">
        <div className="lg:border-r pl-5">
          <div className="text-sm text-gray-400">Mobile</div>
          <div className="text-xl">{userData?.phone ? "+91 "+userData?.phone : (user?.userMobile ? "+91 " + user?.userMobile : "")}</div>
        </div>
        <div className="lg:border-r pl-5 my-5 lg:my-0">
          <div className="text-sm text-gray-400">Gender</div>
          <div className="text-xl">{userData?.gender ? userData?.gender : (user?.userGender ? user.userGender : "-")}</div>
        </div>
        <div className="pl-5">
          <div className="text-sm text-gray-400">Birthday</div>
          <div className="text-xl">{userData?.dob ? userData?.dob : (user?.userDob ? user.userDob : "xx-xx-xxxx")}</div>
        </div>
      </div>
      <Button click={(e) =>{e.preventDefault();setState(state !== true)}} bgHover="darkRed" bgColor="red" textColor="white" text="Edit Profile" />

    </div>
  )
}

export default Dashboard
