
import { React, Component, Fragment, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'

function CategoryListSidebar({ categoryData, setFilterSelectedCategory, filterSelectedCategory, setFilterSelectedCategoryName }) {

  const [selectedCategory, setSelectedCategory] = useState(-1)



  function getCats() {
    const categoryLinks = categoryData && categoryData.category;
    return categoryLinks;
  }

  function openCategory(index) {
    if (selectedCategory === index) {
      setSelectedCategory(-1)
    } else {
      setSelectedCategory(index)
    }
  }

  const category = getCats();
  const items = category && category.length && category.map((item, index) => {
    return <li key={item.id}><a onClick={() => {
      setFilterSelectedCategory(item.id)
      setFilterSelectedCategoryName(item.name)
    }} className={`py-2 opacity-50 hover:opacity-100 cursor-pointer block ${filterSelectedCategory == item.id ? "text-black opacity-100" : "text-black"}`}>{item.name}</a></li>
  });
  return (
    <>
      <ul className="d-block py-3 px-4 bg-gray-50 rounded-xl mt-5 mb-2">
        {items}
      </ul>
    </>
  )
}

export default CategoryListSidebar
// className="w-full py-2 text-sm font-medium focus:outline-none block"
