import React, { Component } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid'
import ProductReviewList from './ProductReviewList';
import parse from 'html-react-parser';


class ProductDetails extends Component {
    render() {
        const {productContent} = this.props
        return (
            <>
          {productContent.description ?
           <div className="grid grid-cols-1 mt-10">
              <h2 className="text-3xl text-brown">Product Details</h2>
              <div>
                 {parse(productContent?.description)}
              </div>
              {/* <p className="text-sm text-black opacity-50 mt-2 mb-0">{productContent.content}</p>  
              {productContent.highlights ?
              <div className="mt-4">
                <h2 className="text-xl text-brown mt-4 mb-3">Highlights</h2>
                {productContent.highlights.map((h)=> (
                   <div className="flex items-center mt-1">
                      <CheckCircleIcon className="w-5 h-5 text-green mr-1"/>
                      {h}    
                   </div>
                ))}
              </div> : "" } 
              {productContent.instructions ?
              <div className="mt-4">
                <h2 className="text-xl text-brown mt-4 mb-3">Instructions</h2>
                {productContent.instructions.map((h)=> (
                   <div className="flex items-center mt-1">
                      <CheckCircleIcon className="w-5 h-5 text-green mr-1"/>
                      {h}    
                   </div>
                ))}
              </div> : "" } 
              {productContent.deliveryDetails ?
              <div className="mt-4">
                <h2 className="text-xl text-brown mt-4 mb-3">Delivery Details</h2>
                <p className="text-sm text-black opacity-50 mt-2 mb-0">{productContent.deliveryDetails}</p> 
              </div> : "" }   */}

           </div>  
           : ""}
           <ProductReviewList ProductReviewList={productContent} />
            </>
        );
    }
}

export default ProductDetails;