import { ShoppingBagIcon } from '@heroicons/react/solid'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../components/button/button'

function Success() {
    return (
        <div className="text-center py-20 border-b">
            <div className="container">
                <img className="w-32 m-auto" src={process.env.PUBLIC_URL + '/images/confetti.png'} alt="#" />
                <h1 className="text-4xl mb-2 mt-10">Your order is complete</h1>
                <p className="mb-6 opacity-50">You will be receiving confirmation email with order details</p>
                <Button bgColor="red" link="/myaccount" text="Track Your Order" bgHover="darkRed" textColor="white" />
            </div>
        </div>
    )
}

export default withRouter(Success)
