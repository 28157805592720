export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const CATEGORIES_LOAD_SUCCESS = "CATEGORIES_LOAD_SUCCESS";
export const CATEGORIES_LOAD_ERROR = "CATEGORIES_LOAD_ERROR";

export const BANNERS_LOADING = "BANNERS_LOADING";
export const BANNERS_LOAD_SUCCESS = "BANNERS_LOAD_SUCCESS";
export const BANNERS_LOAD_ERROR = "BANNERS_LOAD_ERROR";

export const POPULAR_PRODUCTS_LOADING = "POPULAR_PRODUCTS_LOADING";
export const POPULAR_PRODUCTS_LOAD_SUCCESS = "POPULAR_PRODUCTS_LOAD_SUCCESS";
export const POPULAR_PRODUCTS_LOAD_ERROR = "POPULAR_PRODUCTS_LOAD_ERROR";

export const LOCATIONS_LOADING = "LOCATIONS_LOADING";
export const LOCATIONS_LOAD_SUCCESS = "LOCATIONS_LOAD_SUCCESS";
export const LOCATIONS_LOAD_ERROR = "LOCATIONS_LOAD_ERROR";

export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_LOAD_SUCCESS = "PRODUCTS_LOAD_SUCCESS";
export const PRODUCTS_LOAD_ERROR = "PRODUCTS_LOAD_ERROR";

export const INSERT_ORDER_LOADING = "INSERT_ORDER_LOADING";
export const INSERT_ORDER_LOAD_SUCCESS = "INSERT_ORDER_LOAD_SUCCESS";
export const INSERT_ORDER_ERROR = "INSERT_ORDER_ERROR";
export const CLEAR_INSERTED_ORDER = "CLEAR_INSERTED_ORDER";

export const PROFILE_DATA_LOADING = "PROFILE_DATA_LOADING";
export const PROFILE_DATA_LOAD_SUCCESS = "PROFILE_DATA_LOAD_SUCCESS";
export const PROFILE_DATA_ERROR = "PROFILE_DATA_ERROR";
export const CLEAR_PROFILE_DATA = "CLEAR_PROFILE_DATA";


export const PROMO_CODE_DATA_LOADING = "PROMO_CODE_DATA_LOADING";
export const PROMO_CODE_DATA_LOAD_SUCCESS = "PROMO_CODE_DATA_LOAD_SUCCESS";
export const PROMO_CODE_DATA_ERROR = "PROMO_CODE_DATA_ERROR";
export const CLEAR_PROMO_CODE_DATA = "CLEAR_PROMO_CODE_DATA";

