import React from 'react'
import Categories from '../components/category/Categories';

function CategoryList() {
  return (
    <>
      <Categories />
    </>
  )
}

export default CategoryList;