import { ShoppingBagIcon } from '@heroicons/react/solid'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../components/button/button'
import { whatsAppNumberApp } from '../constants/config'

function Failed() {
    return (
        <div className="text-center py-20 border-b">
            <div className="container">
                <img className="w-32 m-auto" src={process.env.PUBLIC_URL + '/images/error-robot.png'} alt="#" />
                <h1 className="text-4xl mb-2 mt-10">Your order is uncompleted</h1>
                <p className="mb-6 opacity-50">Please contact admin for complete yor order</p>
                <Button bgColor="red" link="/help" text="Contact Admin" bgHover="darkRed" textColor="white" />
            </div>
        </div>
    )
}

export default withRouter(Failed)
