import React from 'react'

const CategoryCardLoader = () => (
    <>
        <div class="bg-white sm:p-4 rounded-lg shadow-xl p-2">
            <div class="w-full rounded-md overflow-hidden">
                <div class="lg:h-48 bg-gray-200 md:h-36 w-full object-cover object-center"></div>
                <div className="Review flex products-center mt-4">
                    <div className="text-orange text-md flex products-center"> </div>
                </div>
                <h4 className="sm:text-xl my-2 text-sm bg-gray-200 animate-pulse h-4 w-2/4"></h4>
            </div>
        </div>
    </>
);

export default CategoryCardLoader
