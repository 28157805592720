import React from 'react'
import ProductLists from "../components/products/Products"

function ProductList() {
  return (
    <>
      <ProductLists />
    </>
  )
}

export default ProductList;