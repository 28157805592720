import React, { Component, useContext, useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid'
import { GlobalContext } from '../../../context/Provider';
import CryptoJS from 'crypto-js'
import axiosInstanceProfile from '../../../helpers/axiosInstanceProfile';
import { useHistory } from 'react-router-dom';


function ProductQuantityCart({ product, validPromoCode, setApiProcessing,validatePromoCode,setToLocalStorage }) {

  const history = useHistory()
  const { cart, setCart } = useContext(GlobalContext)

  const [quantity, setQuantity] = useState(0);
  const [currPrice, setCurrPrice] = useState(1);
  const [originalPrice, setOriginalPrice] = useState(1);
  var addOrRemoveSetTest = ""
  function addOrRemoveSet(e) {
    e.preventDefault();
    if (addOrRemoveSetTest === 'add') {
      setQuantity(quantity + 1);
      if (quantity > 0) {
        if (product) {
          setCurrPrice(originalPrice + currPrice)
          addCartQuantity(product.id, product.kg, quantity + 1, originalPrice + currPrice)
        }
      }
    }
    if (addOrRemoveSetTest === 'remove') {
      setQuantity(quantity - 1);
      if (quantity > 1) {
        if (product) {
          subCartQuantity(product.id, product.kg, quantity - 1, currPrice - originalPrice)
        }
      }
      setCurrPrice(currPrice - originalPrice)
    }
  }
  const displayCounter = quantity > 1;

  useEffect(() => {
    if (product) {
      setQuantity(product.quantity)
      setOriginalPrice(product.originalPrice)
      setCurrPrice(product.price)
    }
  }, [])


  const getCartSubTotal = (items) => (items?.reduce((total, item) => total + parseInt(item.price), 0))

  const addCartQuantity = (itemID, kg, quantity, price) => {

    //console.log('price :>> ', price);
    let cartItemsCpy = [...cart.items]
    let existentItem = cartItemsCpy.find(item => item.id == itemID && item.kg == kg);

    if (!existentItem) return
    existentItem.quantity = quantity

    if (!existentItem) return
    existentItem.price = price

    if (existentItem.quantity <= 0) {
      cartItemsCpy = cartItemsCpy.filter(item => {
        if (item.id == itemID) {
          return item.kg !== kg
        } else {
          return item.id !== itemID
        }
      })
    }

    let finalSubTotal = getCartSubTotal(cartItemsCpy)

    if (validPromoCode?.code != "") {
      validatePromoCode(validPromoCode?.code, finalSubTotal)
        .then(code => {
          setToLocalStorage(cartItemsCpy, finalSubTotal, code)
        })
        .finally(function () {
          setApiProcessing(false)
        });
    } else {
      setToLocalStorage(cartItemsCpy, finalSubTotal, {})
    }
  }

  const subCartQuantity = (itemID, kg, quantity, price) => {


    let cartItemsCpy = [...cart.items]

    let existentItem = cartItemsCpy.find(item => item.id == itemID && item.kg == kg);

    if (!existentItem) return
    existentItem.quantity = quantity

    if (!existentItem) return
    existentItem.price = price

    if (existentItem.quantity <= 0) {
      cartItemsCpy = cartItemsCpy.filter(item => {
        if (item.id == itemID) {
          return item.kg !== kg
        } else {
          return item.id !== itemID
        }
      })
    }


    let finalSubTotal = getCartSubTotal(cartItemsCpy);


    if (validPromoCode?.code != "") {
      validatePromoCode(validPromoCode?.code, finalSubTotal)
        .then(code => {
          setToLocalStorage(cartItemsCpy, finalSubTotal, code)
        })
        .finally(function () {
          setApiProcessing(false)
        });
    } else {
      setToLocalStorage(cartItemsCpy, finalSubTotal, {})
    }

  }


  return (
    <div>
      <span className="block text-base mr-5 mb-2">Quantity</span>
      <form onSubmit={addOrRemoveSet}>
        <div className="inline-flex items-center border p-2 rounded-xl">
          {displayCounter && <button className="focus:outline-none" onClick={() => addOrRemoveSetTest = 'remove'}><MinusIcon className="w-8 h-8 bg-brown rounded-xl text-white p-1 hover:text-white hover:bg-red cursor-pointer" /></button>}
          <input className="bg-transparent text-center w-10 ring-0 outline-none" type="text" value={quantity} disabled />
          <button className="focus:outline-none" onClick={() => addOrRemoveSetTest = 'add'}><PlusIcon className="w-8 h-8 bg-brown rounded-xl text-white p-1 hover:text-white hover:bg-red cursor-pointer" /></button>
        </div>
      </form>
    </div>
  )
}

export default ProductQuantityCart
