import { BANNERS_LOADING, BANNERS_LOAD_ERROR, BANNERS_LOAD_SUCCESS } from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstance from "../../helpers/axiosInstance"


export default (history) => (dispatch) => {
    dispatch({
        type: BANNERS_LOADING,
    });
    axiosInstance(history)
        .get('/api/v1/app/get_offer_banner')
        .then(res => {
            // console.log('resBanner :>> ', res);
            dispatch({
                type: BANNERS_LOAD_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            // console.log('err :>> ', err);
            dispatch({
                type: BANNERS_LOAD_ERROR,
                payload: err.response ? err.response.data : CONNECTION_ERROR,
            });
        })
}


