import React from 'react'

function Faq() {
    return (
        <div className="py-10 px-20 border-b">
            <div className="container">

                <h4><strong>Frequently Asked Questions (FAQ)</strong></h4>
                <br />
                <ol className="list-decimal">
                    <li><strong>How can I trust the quality of cake delivered by Mayavi?</strong><br />We have a hygienic and well-equipped bakery where we make delicious and healthy cakes. This allows us to ensure that our customers receive high-quality cakes with consistent taste. When you order from mayavicakes.com, we guarantee that you will receive high-quality products.</li>
                    <br />
                    <li><strong>Will you deliver fresh cakes?</strong><br />A person can only enjoy the true taste of a cake if it is fresh, that's why all of the cakes we deliver are baked just before they are delivered to the customer. To accomplish this, we allow clients to select a delivery time so that we know when we need to prepare a cake and deliver it. We make sure that our customers always get the freshest cake available.</li>
                    <br />
                    <li><strong>Are there any hidden charges (Sales Tax) when I make a purchase on Mayavi?</strong><br />There are NO hidden charges when you make a purchase on Mayavi. The prices listed for all the items are final and all-inclusive. The price you see is exactly what you need to pay. Delivery charges may be extra depending on the location and other parameters.</li>
                    <br />
                    <li><strong>Are there any delivery charges?</strong><br />The cost of delivery varies depending on the location. Our website will show you the delivery charges for that specific location if you input the pin code of the delivery address. Delivery charges range between Rs. 0 &ndash; 300 rupees.</li>
                    <br />
                    <li><strong>What happens if the customer is not there when the delivery person arrives? Will you make another delivery attempt?</strong><br />We won't try to deliver the order again, but we will call the customer to see if we can deliver it to a neighbour or a nearby address. If that's not possible, the order will be cancelled.</li>
                </ol>

            </div>
        </div>
    )
}

export default Faq
