import React, { useContext, useEffect, useState } from "react"
import './App.css'
import { Route, Switch, BrowserRouter as Router, useHistory, Redirect } from 'react-router-dom'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Home from './pages/Home'
import About from './pages/About'
import ProductPage from './pages/ProductList'
import Login from './pages/Login'
import Help from './pages/Help'
import ProductView from "./pages/ProductView"
import Cart from './pages/Cart'
import CheckOut from "./pages/CheckOut"
import MyAccount from "./pages/MyAccount"
import Success from "./pages/Success"
import getCategories from "./context/actions/getCategories"
import { GlobalContext } from "./context/Provider"
import SubCategoryPage from "./pages/SubCategoryList"
import CategoryPage from "./pages/CategoryList"
import ProtectedRoute from "./Routes/ProtectedRoute"
import CryptoJS from 'crypto-js'
import RefundPolicy from "./pages/RefundPolicy"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TermsAndConditions from "./pages/TermsAndConditions"
import Faq from "./pages/Faq"
import ScrollToTop from "./context/ScrollToTop"
import Test from "./pages/Test"
import Failed from "./pages/Failed"

function App() {

  const history = useHistory()
  const { categoriesState, categoriesDispatch } = useContext(GlobalContext)
  useEffect(() => {
    getCategories(history)(categoriesDispatch);
  }, [])


  const { user, setUser } = useContext(GlobalContext)

  let EncryptedAuthUser = localStorage.getItem("user");
  if (EncryptedAuthUser) {
    var bytes = CryptoJS.AES.decrypt(EncryptedAuthUser, 'secret-key@123');
    var AuthUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  // const { profileDataState: { profile: { data: resProfile, error: resProfileError, loading: resProfileLoad } } } = useContext(GlobalContext)

  useEffect(() => {
    if (AuthUser) {
      // if (resProfile?.length > 0) {
        setUser(AuthUser)
      // } else {
      //   localStorage.setItem('user', '')
      //   setUser([])
      // }
    }
  }, [])


  const { cart, setCart } = useContext(GlobalContext)

  let localCartE = localStorage.getItem("cart");
  if (localCartE?.length > 0) {
    var c = CryptoJS.AES.decrypt(localCartE, 'secret-key@123');
    var localCart = JSON.parse(c.toString(CryptoJS.enc.Utf8));
  }
  useEffect(() => {
    if (localCart?.items?.length > 0) {
      // localCart = JSON.parse(localCart);
      setCart(localCart)
    }
    else {
      setCart({
        items: [],
        subTotal: 0,
        promoCode: {}
      })
    }
  }, [])


  return (
    <div className="App">
      <Router basename="/">
        <ScrollToTop>
          <Header categoriesState={categoriesState} cart={cart} user={user} />
          {/* <HeaderTemp categoriesState={categoriesState} cart={cart} user={user} /> */}
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/help" component={Help} />
            {/* <Route exact path="/category" component={SubCategoryPage} /> */}
            <Route exact path="/category" component={CategoryPage} />
            {/* <Route path="/category/:catId" component={CategoryPage} /> */}
            <Route exact path="/products" component={ProductPage} />
            <Route path="/products/:catId" component={ProductPage} />
            <Route exact path="/products_view/:productId" component={ProductView} />
            {/* <Route path="/cart" component={Cart} /> */}
            <Route path="/refund" component={RefundPolicy} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsAndConditions} />
            <Route path="/faq" component={Faq} />
            <Route path="/login" render={() => (
              user ? (
                <Redirect to="/" />
              ) : (
                <Login />
              )
            )} />

            {
              cart.items?.length > 0 ? <ProtectedRoute path="/cart" component={Cart} isAuth={user} /> : <Route path="/cart" component={Cart} />
            }


            <ProtectedRoute path="/myaccount" component={MyAccount} isAuth={user} />
            <ProtectedRoute path="/checkout" component={CheckOut} isAuth={user} cart={cart} />
            <ProtectedRoute path="/success" component={Success} isAuth={user} />
            <ProtectedRoute path="/failed" component={Failed} isAuth={user} />

            {/* <Route path="/test" component={Test} /> */}

          </Switch>
          <Footer user={user} />
        </ScrollToTop>
      </Router>

    </div>
  );
}

export default App;
