import React from 'react'
import Button from '../../button/button'
import ProductCard from '../../products/product/ProductCard'


function ProductsPopular({
   popularProductsState: {
      popularProducts: { loading, error, data }
   }
}) {
   return (
      <div className="sm:mx-6 pt-16 pb-24 relative m-pt-0 m-pb-20">
         <div className="container">
            <div className="sm:flex sm:justify-between mb-10 m-mb-5">
               <h5 className="md:text-3xl sm:text-lg text-2xl text-brown m-fs-18">Popular Cakes</h5>
               {/* <a className="mt-4 sm:mt-0 block text-red underline hover:text-darkRed hover:no-underline md:text-xl sm:text-sm" href="">View All Products</a> */}
            </div>
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-3 sm:gap-8 mb-12 m-mb-0">
               <ProductCard productsData={data} displayProducts={data.products} productsImgUrl={data.product_base_url} />
            </div>
            {/* <div className="text-center">
               <Button textHover="white" bgHover="black" link="#" bgColor="gray-200" size="xl" text="Load More" />
            </div> */}

         </div>
      </div>
   )
}

export default ProductsPopular