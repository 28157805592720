import React from 'react'

function TermsAndConditions() {
    return (
        <div className="py-10 px-20 border-b">
            <div className="container">

                <p><strong>Terms and Conditions</strong></p>
                <p>&nbsp;</p>
                <p>Please read the following terms and conditions very carefully as your use of service is subject to your acceptance of and compliance with the following terms and conditions ("Terms").</p>
                <p>&nbsp;</p>
                <p>By subscribing to or using any of our services you agree that you have read, understood and are bound by the Terms, regardless of how you subscribe to or use the services. If you do not want to be bound by the Terms, you must not subscribe to or use our services.</p>
                <p>&nbsp;</p>
                <p>Your use of the Website, services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Website, including the applicable policies which are incorporated herein by way of reference. If you transact on the Website, You shall be subject to the policies that are applicable to the Website for such transactions. By mere use of the Website, You shall be contracting with Mayavi Online Solutions Private Limited and these terms and conditions including the policies constitute Your binding obligations, with Mayavi.</p>
                <p>&nbsp;</p>
                <p>By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by the Mayavi Privacy Policy.</p>
                <p>&nbsp;</p>
                <p>For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a member of the Website by providing Registration Data while registering on the Website as Registered User using the computer systems. The terms "we", "us", "our" shall mean Mayavi Online Solutions Private Limited.</p>
                <p>&nbsp;</p>
                <p>When You use any of the services provided by us through the Website, including but not limited to, (e.g. Customer Reviews), You will be subjected to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to you. It is your responsibility to review these Terms of Use periodically for updates/changes. Your continued use of the Website following the posting of changes will mean that you accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Website.</p>
                <p>&nbsp;</p>
                <p>ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.</p>
                <p>&nbsp;</p>
                <p><strong>Introduction:</strong></p>
                <ol>
                    <li>a) <a href="http://www.mayavicakes.com/">www.mayavicakes.com</a> website ("Website") is an Internet based content and e-commerce portal owned and operated by Mayavi Online Solutions Private Limited, a company incorporated under the laws of India.</li>
                </ol>
                <ol start="2">
                    <li>b) Use of the Website is offered to you conditioned on acceptance without modification of all the terms, conditions and notices contained in these Terms, as may be posted on the Website from time to time. Mayavicakes.com at its sole discretion reserves the right not to accept a User from registering on the Website without assigning any reason thereof.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Headings:</strong></p>
                <p>The headings and subheadings herein are included for convenience and identification only and are not intended to describe, interpret, define or limit the scope, extent or intent of the Terms or the right to use the Website by you contained herein or any other section or pages of the Website or any Linked Sites in any manner whatsoever.</p>
                <p>&nbsp;</p>
                <p><strong>User Account, Password, and Security:</strong></p>
                <p>You will receive a password and account designation upon completing the Website's registration process. You are responsible for maintaining the confidentiality of the password and account and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Mayavicakes.com of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. Mayavicakes.com cannot and will not be liable for any loss or damage arising from your failure to comply with this Section (b).</p>
                <p>&nbsp;</p>
                <p>You agree that if any information provided by You that is inconsistent, inaccurate, not current or incomplete, or We have reasonable grounds to suspect that such information is inconsistent, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, We reserve the right to indefinitely suspend or terminate or block access of your membership on the Website and refuse You access to the Website.</p>
                <p>&nbsp;</p>
                <p><strong>Communications:</strong></p>
                <p>When You use the Website or send emails or other data, information or communication to Company, You agree and understand that You are communicating with Company through electronic records and You consent to receive communications via electronic records from the Company periodically and as and when required. The company may communicate with you by email or by such other mode of communication, electronic or otherwise.</p>
                <p>&nbsp;</p>
                <p><strong>Services Offered:</strong></p>
                <p>Mayavicakes.com provides a number of Internet-based services through the Web Site (all such services, collectively, the "Service"). One such service enables users to purchase original merchandise as gifts such as watches, perfumes, sunglasses, belts, and other gift items from various brands. (Collectively, "Products"). Upon placing an order, Mayavicakes.com shall ship the product to you or the person you are gifting and you be entitled to its payment for the Services.</p>
                <p>&nbsp;</p>
                <p><strong>Use of the Website:</strong></p>
                <p>You agree, undertake and confirm that your use of Website shall be strictly governed by the following binding principles:</p>
                <p>&nbsp;</p>
                <p><strong>Charges</strong></p>
                <p>Membership on the Website is free for buyers. Mayavi does not charge any fee for browsing and buying on the Website. Mayavi reserves the right to change its Fee Policy from time to time. In particular, Mayavi may at its sole discretion introduce new services and modify some or all of the existing services offered on the Website. In such an event Mayavi reserves the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be. Changes to the Fee Policy shall be posted on the Website and such changes shall automatically become effective immediately after they are posted on the Website. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for compliance with all applicable laws including those in India for making payments to Mayavi online solutions private limited.</p>
            </div>
        </div>
    )
}

export default TermsAndConditions
