import React, { useContext, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ChevronDownIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import FeatherIcons from 'feather-icons-react'
import getLocations from '../../../context/actions/getLocations';
import { GlobalContext } from '../../../context/Provider';
import { useHistory } from 'react-router-dom';
import { LocationLocalStorage } from '../../../storage/localStorage';

export default function Location() {
  const history = useHistory();
  const { locationsState: {
    locations: { data, error, loading }
  }, locationsDispatch } = useContext(GlobalContext)
  useEffect(() => {
    getLocations(history)(locationsDispatch);
  }, [])

  const [locSelected, setLocSelected] = LocationLocalStorage(
    'location'
  );
  // const [locSelected, setLocSelected] = useState("")
  return (
    <div className="text-right m-none">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="items-center text-left ml-6 flex focus:ring-0 focus:outline-none">
            <FeatherIcons className="text-orange md:bg-white md:p-0 rounded bg-gray-200 p-2" strokeWidth="1.5" size="36" icon="map-pin" />
            <div className="ml-2 hidden md:block">
              <span className="text-sm text-black block opacity-50">Choose your city</span>
              <div className="flex items-center">
                {locSelected.name ? locSelected.name : "Location"}
                <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
              </div>

            </div>
          </Menu.Button>

        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
            <div className="px-1 py-1 ">
              {
                data.locations?.map((loc) => (
                  <Menu.Item id={loc.id} >
                    {({ active }) => (
                      <button
                        className={`${active ? 'bg-red text-white' : 'text-gray-500'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => {
                          setLocSelected({ id: loc.id, name: loc.name })
                        }}
                      >
                        {active ? (
                          <LocationMarkerIconFill
                            className="w-5 h-5 mr-2"
                          />
                        ) : (
                          <LocationMarkerIcon
                            className="w-5 h-5 mr-2"
                          />
                        )}
                        {loc.name}
                      </button>
                    )}
                  </Menu.Item>
                ))
              }
            </div>

          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

function LocationMarkerIconFill() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
    </svg>
  )
}
