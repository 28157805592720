import React, { Component, useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid'


function ProductQuantity({ setQuantity, quantity, currPrice, setCurrPrice, originalPrice, ogPrice,ogCurrPrice,setOgCurrPrice }) {

  var addOrRemoveSetTest = ""
  function addOrRemoveSet(e) {
    e.preventDefault();
    if (addOrRemoveSetTest === 'add') {
      setQuantity(quantity + 1);
      if (quantity > 0) {
        setCurrPrice(currPrice + originalPrice)
        setOgCurrPrice(ogCurrPrice + ogPrice)
      }
    }
    if (addOrRemoveSetTest === 'remove') {
      setQuantity(quantity - 1);
      if (quantity > 1) {
        setCurrPrice(currPrice - originalPrice)
        setOgCurrPrice(ogCurrPrice - ogPrice)
      }
    }
  }
  const displayCounter = quantity > 1;

  return (
    <div>
      <span className="block text-base mr-5 mb-2">Quantity</span>
      <form onSubmit={addOrRemoveSet}>
        <div className="inline-flex items-center border p-2 rounded-xl">
          {displayCounter && <button className="focus:outline-none" onClick={() => addOrRemoveSetTest = 'remove'}><MinusIcon className="w-8 h-8 bg-brown rounded-xl text-white p-1 hover:text-white hover:bg-red cursor-pointer" /></button>}
          <input className="bg-transparent text-center w-10 ring-0 outline-none" type="text" value={quantity} disabled />
          <button className="focus:outline-none" onClick={() => addOrRemoveSetTest = 'add'}><PlusIcon className="w-8 h-8 bg-brown rounded-xl text-white p-1 hover:text-white hover:bg-red cursor-pointer" /></button>
        </div>
      </form>
    </div>
  )
}

export default ProductQuantity
