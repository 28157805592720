import { INSERT_ORDER_ERROR, INSERT_ORDER_LOADING, INSERT_ORDER_LOAD_SUCCESS } from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstanceProfile from "../../helpers/axiosInstanceProfile";

export default (({ shipping_name: shipping_name, shipping_phone: shipping_phone, shipping_address: shipping_address, shipping_location: store, shipping_landmark: shipping_landmark, shipping_email: shipping_email, payment_method: payment_method, items: items, selectedDate: delivery_date, selectedSlot: delivery_date_slot, transaction_id: transaction_id, status, payment_status,promo_code_name }) => (dispatch) => {
    dispatch({
        type: INSERT_ORDER_LOADING
    });

    axiosInstanceProfile().post('api/v1/app/insert_order', {
        store,
        shipping_name,
        shipping_phone,
        shipping_address,
        shipping_landmark,
        shipping_email,
        payment_method,
        items,
        delivery_date,
        delivery_date_slot,
        transaction_id: transaction_id ? transaction_id : "",
        status: status ? status : "",
        payment_status: payment_status ? payment_status : "",
        promo_code_name: promo_code_name ? promo_code_name : ""
    })
        .then((res) => {
            // console.log(`res`, res)
            dispatch({
                type: INSERT_ORDER_LOAD_SUCCESS,
                payload: res.data,
            });
        }).catch((err) => {
            // console.log(`err`, err)
            dispatch({
                type: INSERT_ORDER_ERROR,
                payload: err.response ? err.response.data : CONNECTION_ERROR,
            })
        })
})