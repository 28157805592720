import { CLEAR_PROMO_CODE_DATA, PROMO_CODE_DATA_ERROR, PROMO_CODE_DATA_LOADING, PROMO_CODE_DATA_LOAD_SUCCESS } from "../../constants/actionTypes"

const promoCode = (state, { payload, type }) => {
    switch (type) {
        case PROMO_CODE_DATA_LOADING: {
            return {
                ...state,
                promoCode: {
                    ...state.promoCode,
                    loading: true,
                    error: null,
                }
            }
        }
        case PROMO_CODE_DATA_LOAD_SUCCESS: {
            return {
                ...state,
                promoCode: {
                    ...state.promoCode,
                    loading: false,
                    data: payload,
                },
            }
        }
        case PROMO_CODE_DATA_ERROR: {
            return {
                ...state,
                promoCode: {
                    ...state.promoCode,
                    loading: false,
                    error: payload
                },
            }
        }

        case CLEAR_PROMO_CODE_DATA: {
            return {
                ...state,
                promoCode: {
                    ...state.promoCode,
                    loading: false,
                    error: payload,
                    data: null
                },
            }
        }

        default:
            return state;
    }
}


export default promoCode;