import { POPULAR_PRODUCTS_LOADING, POPULAR_PRODUCTS_LOAD_ERROR, POPULAR_PRODUCTS_LOAD_SUCCESS } from "../../constants/actionTypes";

const popularProducts = (state, { payload, type }) => {
    switch (type) {
        case POPULAR_PRODUCTS_LOADING: {
            return {
                ...state,
                popularProducts: {
                    ...state.popularProducts,
                    loading: true,
                },
            }
        }
        case POPULAR_PRODUCTS_LOAD_SUCCESS: {
            return {
                ...state,
                popularProducts: {
                    ...state.popularProducts,
                    loading: false,
                    data: payload,
                },
            }
        }
        case POPULAR_PRODUCTS_LOAD_ERROR: {
            return {
                ...state,
                popularProducts: {
                    ...state.popularProducts,
                    loading: false,
                    error: payload
                },
            }
        }
        default:
            return state;
    }
}


export default popularProducts;