import React, { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  CakeIcon,
  ViewGridIcon,
  MenuIcon,
  XIcon,
  PhoneIcon,
  ChevronRightIcon,
  SpeakerphoneIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import FeatherIcon from 'feather-icons-react'
import Search from './search/Search'
import Location from './location/Location'
import { Link, NavLink } from 'react-router-dom'
import SearchBar from './search/SearchBar'
import { whatsAppNumberApp } from '../../constants/config'
import axiosInstance from '../../helpers/axiosInstance'
import { useHistory } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';


function Header({ categoriesState:
  { categories: { data, error, loading } },
  cart,
  user
}) {

  const history = useHistory();

  const [selectedCategory, setSelectedCategory] = useState(-1)
  const [hide, setHide] = useState(true)

  const [category, setCategory] = useState([])
  const [categoryImageUrl, setCategoryImageUrl] = useState('')

  useEffect(() => {
    setCategoryImageUrl(data.category_base_url)
    setCategory(data.category)
  }, [data])

  function handleClick(index) {
    if (selectedCategory === index) {
      setSelectedCategory(-1)
    } else {
      setSelectedCategory(index)
    }
  }

  function classNames(...classes) {

    return classes.filter(Boolean).join(' ')

  }

  const callsToAction = [
    { name: 'View All Products', href: '/products', icon: ViewGridIcon },
    { name: 'Contact Sales', href: '/help', icon: PhoneIcon },
  ]

  return (
    <>
      <Toaster />
      <a href={`http://wa.me/${whatsAppNumberApp}`} target="_blank" className="block w-12 h-12 fixed z-30 bottom-8 right-8 whatsapp"><img className="w-max-full" src={process.env.PUBLIC_URL + '/images/whatsapp.png'} alt="Derby Logo" /></a>
      {hide ?
        <div className="bg-gradient-to-r from-gray-100 to-gray-200">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-white">
                  <SpeakerphoneIcon className="h-6 w-6 text-orange" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-black truncate">
                  <span className="md:hidden">Download Our App</span>
                  <span className="hidden md:inline">For fast ordering experience download the Mayavi app</span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <div className="flex items-center">
                  <a className="w-32 block mr-3" onClick={(e) =>  toast.error('Will be available soon.')}><img className="max-w-full" src={process.env.PUBLIC_URL + '/images/play_store@2x.png'} alt="Mayavi Android App" /></a>
                  <a className="w-32 block" onClick={(e) =>  toast.error('Will be available soon.')}><img className="max-w-full" src={process.env.PUBLIC_URL + '/images/app_store@2x.png'} alt="Mayavi Android App" /></a>
                </div>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button onClick={() => setHide(false)} type="button" className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2">
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-black" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
        : null}
      <Popover className="relative bg-white">
        {({ open }) => (
          <>
            <div className="container">
              <div className="flex justify-between items-center py-3 md:py-6 xl:justify-start xl:space-x-10">
                <div className="flex items-center xl:w-0 xl:flex-1">
                  <Link to="/">
                    <img
                      className="logo h-16 w-auto"
                      src={process.env.PUBLIC_URL + '/images/logo.svg'}
                      alt="Mayavi Logo"
                    />
                  </Link>

                  <Location />


                </div>

                <div className="lg:block lg:w-6/12 xl:w-3/12 sm:w-12/12 m-100">
                  {/* <Search /> */}
                  <SearchBar />
                </div>


                <Popover.Group as="nav" className="hidden xl:flex space-x-10">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-red' : 'text-black',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-0'
                          )}
                        >
                          <span>All Categories</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-gray-400',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel static
                            className="absolute z-20 -ml-4 mt-3 transform px-2 w-screen w-max sm:px-0 xl:ml-0 xl:left-1/2 xl:-translate-x-1/2"
                          >

                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">


                                {category ? category?.map((item, index) => (
                                  <Popover.Button>
                                    <Link
                                       to={{
                                        pathname: `/products/${item.id}`,
                                        state: { catName: item.name }
                                      }} 
                                      key={index} onMouseEnter={() => handleClick(index)} className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 text-left relative cursor-pointer">

                                      {selectedCategory === index ?
                                        <ChevronRightIcon className="-mr-1 ml-2 h-4 w-4 text-gray-400  absolute right-6 top-4" aria-hidden="true" />
                                        : <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4 text-gray-400  absolute right-4 top-4" aria-hidden="true" />
                                      }
                                      {
                                        item.icon ? <img src={categoryImageUrl + item.icon} className="flex-shrink-0 h-6 w-6 text-indigo-600 rounded" alt="" />
                                          :
                                          <CakeIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                                      }

                                      <div className="ml-4">
                                        <p className="text-base font-medium text-black cursor-pointer">{item.name}</p>

                                        {selectedCategory === index && item.products.length > 0 ?
                                          <div className="absolute bg-white shadow-xl -right-52 top-0 w-8/12 rounded-xl p-4">
                                            {Array.isArray(item.products) && item.products.map((pro) => {
                                              return (
                                                <Link to={`/products_view/${pro.id}`} className="mt-1 mb-2 mr-2 text-sm text-gray-400 hover:text-black transition duration-500 block">{pro.name}</Link>
                                              )
                                            })
                                            }
                                          </div>
                                          : ""}

                                      </div>
                                    </Link>
                                  </Popover.Button>
                                )) :
                                  <Popover.Button>
                                    <Link to="" className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 text-left relative cursor-pointer">
                                      <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4 text-gray-400  absolute right-4 top-4" aria-hidden="true" />
                                      <CakeIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-base font-medium text-black cursor-pointer">Category</p>
                                      </div>
                                    </Link>
                                  </Popover.Button>
                                }


                              </div>
                              <div className="px- py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6">
                                {callsToAction.map((item) => (
                                  <Popover.Button>
                                    <div key={item.name} className="flow-root">
                                      <Link
                                        to={item.href}
                                        className="-m-3 p-3 flex items-center rounded-md text-sm font-medium text-gray-900 hover:bg-gray-100"
                                      >
                                        <item.icon className="flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                                        <span className="ml-3">{item.name}</span>
                                      </Link>
                                    </div>
                                  </Popover.Button>
                                ))}
                              </div>
                            </div>

                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  {/* <NavLink to={user ? "/myaccount" : "/login"} activeClassName="" className="text-base font-medium text-gray-500 hover:text-red disabled">
                    {user ? "Profile" : "Login/Signup"}
                  </NavLink> */}
                  <NavLink to="/help" activeClassName="" className="text-base font-medium text-gray-500 hover:text-red">
                    Help
                  </NavLink>
                </Popover.Group>
                  {/* toast.error('Your Cart is Empty.') */}
                <div className="cart_ flex items-center disabled">
                  <Link onClick={(e) => cart?.items?.length > 0 ? history.push("/cart") : history.push("/cart")} className="xl:ml-8 whitespace-nowrap inline-flex items-center relative justify-center rounded-md text-white bg-red hover:bg-red w-14 h-14 m-none">
                    <FeatherIcon icon="shopping-cart" />
                    <span className="absolute w-5 h-5 bg-black text-white rounded-full flex items-center justify-center top-1 right-1 text-sm ">{cart?.items?.length ?? 0}</span>
                  </Link>

                  <div className="xl:hidden ml-2 sm-4">
                    <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="h-6 w-6 " aria-hidden="true" />
                    </Popover.Button>
                  </div>

                </div>

              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden z-50"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-12 w-auto"
                          src={process.env.PUBLIC_URL + '/images/logo.svg'}
                          alt="Mayavi Logo"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        {category?.map((item, index) => (
                          <div onClick={() => handleClick(index)} key={index} className="cursor-pointer -m-3 p-3 flex items-center rounded-md hover:bg-gray-50 relative"
                          >
                            {selectedCategory === index ?
                              <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4 text-gray-400  absolute right-4 top-4" aria-hidden="true" />
                              : <ChevronRightIcon className="-mr-1 ml-2 h-4 w-4 text-gray-400  absolute right-4 top-4" aria-hidden="true" />
                            }
                            {
                              item.icon ? <img src={categoryImageUrl + item.icon} className="flex-shrink-0 h-6 w-6 text-indigo-600 rounded" alt="" />
                                :
                                <CakeIcon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                            }
                            <div className="ml-3">
                              <span className="text-base font-medium text-gray-900 block">{item.name}</span>
                              {selectedCategory === index ? <>
                                {Array.isArray(item.sub_category) && item.sub_category.map((child) => {
                                  return (
                                    <>
                                      {Array.isArray(child.products) && child.products.map((pro) => {
                                        return (
                                          <Link to={`/products_view/${pro.id}`} className="mt-1 mr-2 text-sm text-gray-400 hover:text-black transition duration-500 block">{pro.name}</Link>
                                        )
                                      })
                                      }
                                    </>
                                  )
                                })}
                              </> : ""}
                            </div>
                          </div>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div className="grid grid-cols-1">
                      <SearchBar />
                      {/* <Search /> */}
                    </div>
                    {/* <div>
                      {user ?
                        <Link
                          to="/myaccount"
                          className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange hover:bg-indigo-700"
                        >
                          My Account
                        </Link>
                        : <>
                          <Link
                            to="/login"
                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange hover:bg-indigo-700"
                          >
                            Sign up
                          </Link>
                          <p className="mt-6 text-center text-base font-medium text-gray-500">
                            Existing customer?{' '}
                            <Link to="/login" className="text-indigo-600 hover:text-indigo-500">
                              Sign in
                            </Link>
                          </p>
                        </>
                      }
                    </div> */}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  )
}

export default Header
