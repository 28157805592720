import React, { Fragment, useContext, useEffect, useState } from 'react'
import Button from '../button/button'
import { Transition } from '@headlessui/react'
import axiosInstanceProfile from '../../helpers/axiosInstanceProfile';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/Provider';
import getProfileData from '../../context/actions/getProfileData';
import toast, { Toaster } from 'react-hot-toast';

function EditProfile({ userData, state, setState }) {
    const { profileDataDispatch } = useContext(GlobalContext)
    const history = useHistory()
    const [name, setName] = useState(userData?.name || "")
    const [email, setEmail] = useState(userData?.email || "")
    const [dob, setDob] = useState(userData?.dob || "")
    const [gender, setGender] = useState(userData?.gender || "")
    const [loader, setLoader] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        if (name != "") {
            setLoader(true)
            let insData = {
                name: name,
                email: email,
                gender: gender,
                dob: dob
            }
            axiosInstanceProfile(history).post('/api/v1/app/update_profile',
                insData
            )
                .then((res) => {
                    if (res?.data?.status && res?.data?.action_status) {
                        setLoader(false)
                        getProfileData(history)(profileDataDispatch)
                        setState(false)
                    }
                })
                .catch(err => console.log(err));
        } else {
            toast.error("Please Enter Your Name")
        }
    }
    return (
        <div>
            <Toaster />
            {state ?
                <div className="fixed inset-0 z-50">
                    <div className="bg-black absolute inset-0 opacity-50" onClick={() => setState(state !== true)}></div>
                    <Transition
                        show={state}
                        as={Fragment}
                        appear={state}
                        enter="transition ease-out duration-500"
                        enterFrom="scale-50 opacity-0"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-white rounded-2xl absolute top-1/2 right-10 left-10 lg:right-auto mg:w-full lg:w-2/6 lg:left-1/2 transform lg:-translate-x-1/2 -translate-y-1/2 p-10 z-10">
                            <h1 className="text-2xl mb-4">Edit Profile</h1>
                            <form>
                                <label className="block text-sm text-brown mb-2">FullName</label>
                                <input className="border w-full h-12 rounded outline-none px-3" type="text" defaultValue={name} onChange={e => setName(e.target.value)} id="" />
                                <div className="h-5"></div>
                                <label className="block text-sm text-brown mb-2">Email</label>
                                <input className="border w-full h-12 rounded outline-none px-3" type="email" defaultValue={email} onChange={e => setEmail(e.target.value)} id="" />
                                {/* <div className="h-5"></div>
                                <label className="block text-sm text-brown mb-2">Phone</label>
                                <input className="border w-full h-12 rounded outline-none px-3" type="email" id="" /> */}
                                <div className="h-5"></div>
                                <label className="block text-sm text-brown mb-2">Birthday</label>
                                <input className="border w-full h-12 rounded outline-none px-3" type="date" max={new Date().toISOString().split("T")[0]} defaultValue={dob} onChange={e => setDob(e.target.value)} id="" />
                                <div className="h-5"></div>
                                <label className="block text-sm text-brown mb-2">Gender</label>
                                <select className="border w-full h-12 rounded outline-none px-3" name="name" defaultValue={gender} onChange={e => setGender(e.target.value)} id="">
                                    <option value="" disabled selected>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                                <div className="h-8"></div>
                                <Button bgHover="darkRed" click={(e) => { handleSubmit(e) }} text="Update Profile" bgColor="red" textColor="white" loader={loader} />
                            </form>
                        </div>
                    </Transition>
                </div> : null}
        </div>
    )
}

export default EditProfile
