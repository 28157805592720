import React, { useContext, useEffect, useState } from "react";
import Downshift from "downshift";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import getProducts from "../../../context/actions/getProducts";
import { GlobalContext } from "../../../context/Provider";
import { useHistory } from "react-router-dom";



export default function SearchBar(props) {
    const history = useHistory()
    const [selectionValue, setSelectionValue] = useState("")

    const { productsState: {
        products: { data, error, loading }
    }, productsDispatch } = useContext(GlobalContext)

    useEffect(() => {
        getProducts(history)(productsDispatch);
    }, []);


    return (
        <Downshift
            onChange={selection => {
                // selection ? setSelectionValue(selection.value) : setSelectionValue() 
                selection?.id > 0 && history.push(`/products_view/${selection.id}`)
            }}
            itemToString={item => (item ? item.name : "")}
        >
            {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                clearSelection
            }) => (
                <div className="search bg-gray-100 rounded-md h-12 sm:h-14 m-0 flex">
                    <span className="w-auto flex justify-end items-center text-gray-500 ml-3">
                        <SearchIcon className="h-5 w-5 text-gray-400" />
                    </span>
                    <input className="w-full rounded p-2 bg-transparent outline-none focus:ring-0" type="text" placeholder="Search your favorite cakes" value={selectionValue}
                        {...getInputProps()}
                    />
                    {
                        selectedItem ? <span onClick={() => { clearSelection() }} className="w-auto flex justify-end items-center text-gray-500 mr-3">
                            <XIcon className="h-5 w-5 text-gray-400" />
                        </span> : ""
                    }

                    {

                        !loading && data && <ul className={`absolute mt-14 ml-6 w-72 overflow-y-auto ${isOpen && "max-h-48"}  rounded bg-white z-10 rounded-lg shadow-lg`} {...getMenuProps()}>
                            {isOpen
                                ? data?.products
                                    .filter(
                                        item =>
                                            !inputValue ||
                                            item.name.toLowerCase().includes(inputValue.toLowerCase())
                                    )
                                    .map((item, index) => (
                                        <li
                                            {...getItemProps({
                                                key: item.id,
                                                index,
                                                item,
                                                className: `py-2 px-2 ${highlightedIndex === index
                                                    ? "text-gray-900 cursor-pointer hover:bg-gray-50"
                                                    : "text-gray-500"
                                                    }`
                                            })}
                                        >
                                            <div className="p-1 flex items-start rounded-lg hover:bg-gray-50 text-left relative cursor-pointer">
                                                <span class="flex items-center">
                                                    <img src={data?.product_base_url + item.image} className="flex-shrink-0 h-6 w-6 rounded" alt="" />
                                                    <span class="ml-3 block truncate">
                                                        {item.name}
                                                    </span>
                                                </span>
                                            </div>

                                        </li>
                                    ))
                                : null}
                        </ul>
                    }
                </div>
            )}
        </Downshift>
    );
}
