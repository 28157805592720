import classnames from 'classnames';
import { Link } from "react-router-dom"


function Button({ size, bgColor, textColor, bgHover, text, link, textHover, click, width, loader, disabled, padding, icon="" }) {
    return (
        <Link onClick={click} to={link} className={classnames("bg-" + bgColor, width, "text-" + textColor, disabled && "cursor-not-allowed", "hover:bg-" + bgHover, "hover:text-" + textHover, " rounded-lg inline-block cursor-pointer text-center", padding ?? "py-3 px-8", { "text-xs": size === 'sm', "text-xl": size === 'lg' })}>
            {/* {text } */}
            {<>{text}
                {icon && <span class="ml-2">
                    <i class={classnames("fa-brands fa-whatsapp", " fa-1x")}></i>
                </span>}
                {loader && <span class="ml-2">
                    <i class="fas fa-circle-notch fa-spin fa-1x"></i>
                </span>}
            </>}
        </Link>
    )
};

export default Button;