import { PRODUCTS_LOADING, PRODUCTS_LOAD_ERROR, PRODUCTS_LOAD_SUCCESS } from "../../constants/actionTypes";

const Products = (state, { payload, type }) => {
    switch (type) {
        case PRODUCTS_LOADING: {
            return {
                ...state,
                products: {
                    ...state.products,
                    loading: true,
                },
            }
        }
        case PRODUCTS_LOAD_SUCCESS: {
            return {
                ...state,
                products: {
                    ...state.products,
                    loading: false,
                    data: payload,
                },
            }
        }
        case PRODUCTS_LOAD_ERROR: {
            return {
                ...state,
                products: {
                    ...state.products,
                    loading: false,
                    error: payload
                },
            }
        }
        default:
            return state;
    }
}


export default Products;