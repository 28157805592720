import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { GlobalContext } from '../../context/Provider'
import PageBanner from '../common/PageBanner'
import CategoryListSidebarCheck from '../products/category/CategoryListSidebarCheck'
import CategoryCard from './CategoryCard'


export default function Categories(props) {

   const { categoriesState: {
      categories: { data, error, loading }
   } } = useContext(GlobalContext)

   const { catId } = useParams();
   const location = useLocation();

   const [categories, setCategories] = useState([])
   const [selectedCategory, setSelectedCategory] = useState(0)
   const [selectedCategoryName, setSelectedCategoryName] = useState("")

   useEffect(() => {
      setCategories(data)
      if (catId) {
         setSelectedCategory(parseInt(catId))
      }
      location.state?.catName && setSelectedCategoryName(location.state.catName)
   }, [data])
   return <div className="categories-page product-page border-b pb-20 m-pb-30">

      <div className="container">
         <PageBanner title={selectedCategoryName ? selectedCategoryName : "Categories"} />
         <div className="grid lg:grid-cols-4 md:grid-cols-5 gap-6">
            {/* <div className="bg-white border p-6 rounded-2xl md:col-span-2 lg:col-auto hidden">
               <h2 className="text-xl text-red mb-4">Categories</h2>
               <CategoryListSidebarCheck categoriesData={categories} setSelectedCategoryName={setSelectedCategoryName} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
            </div> */}
            <div className="md:col-span-12">
               <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 sm:gap-8 gap-3">
                  <CategoryCard categoriesData={categories} selectedCategory={selectedCategory} />
               </div>
            </div>
         </div>
      </div>
   </div>
}





