import React, { useEffect, useState } from "react";
import createSlots from "../../../helpers/slotsGenerator";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import format_date from "../../../helpers/formatDate";
import SelectSlot from "./SelectSlot";


export const DatePick = ({ minDate, setSelectedDate }) => {
    const [startDate, setStartDate] = useState(minDate);
    return (
        <DatePicker className="outline-none w-full" dateFormat="dd/MM/yyyy" minDate={minDate} selected={startDate} onChange={(date) => { setStartDate(date); setSelectedDate(format_date(date)) }} onFocus={e => e.target.blur()} />
    );

};

function BookingSlot({ setSelectedDate, selectedDate, setSelectedSlot, timeDelay }) {

    const [start, setStart] = useState(0)

    const closeHour = 17;
    const startHour = 8;
    const slotEndHour = 17;

    var delayTime = timeDelay;

    var availableFrom = new Date();
    if (availableFrom.getHours() >= closeHour) {
        availableFrom.setDate(availableFrom.getDate() + 1);
        availableFrom.setHours(startHour);
    }
    if (availableFrom.getHours() < closeHour) {
        // if (availableFrom.getMinutes() < 30) {
        //     availableFrom.setHours(availableFrom.getHours() - 1);
        // } else {
        availableFrom.setHours(availableFrom.getHours());
        // }
    }
    var timeLeft = closeHour - availableFrom.getHours();
    while (delayTime >= timeLeft) {
        delayTime -= timeLeft;
        availableFrom.setDate(availableFrom.getDate() + 1);
        availableFrom.setHours(startHour);
        timeLeft = closeHour - availableFrom.getHours();
    }
    availableFrom.setHours(availableFrom.getHours() + delayTime);


    useEffect(() => {
        if (availableFrom.toISOString().split("T")[0] == selectedDate) {
            setStart(("0" + availableFrom.getHours()).slice(-2))
        } else {
            setStart(("0" + startHour).slice(-2))
        }
    }, [selectedDate])


    var slotConfig = {
        configSlotHours: "01",
        configSlotMinutes: "0",
        configSlotPreparation: "0",
        timeArr: [{ startTime: `${start}:00`, endTime: `${slotEndHour}:00` }]
    };

    const slots = createSlots(slotConfig)
    // const getSelectField = () => {
    //     return <select className="outline-none w-full" onChange={(e) => { setSelectedSlot(e.target.value) }}>
    //         <option>Select Time Slot</option>
    //         {slots?.map((t, key) => (
    //             <option key={key} value={t.timeEnd}>{t.timeSlotEnd}</option>
    //         ))}
    //     </select>
    // }


    return (
        <>
            <div className="w-full sm:w-auto">
                <span className="block text-base mr-5">Choose Date</span>
                <div className="border w-full py-3 pl-4 rounded-xl mt-2"><DatePick minDate={availableFrom} setSelectedDate={setSelectedDate} /></div>
            </div>

            <div className="w-full sm:w-auto my-4 sm:my-0 sm:mx-4">
                <span className="block text-base mr-5">Choose Time</span>
                <div className="border w-full py-3 pl-4 rounded-xl mt-2">
                    <select className="outline-none w-full" onChange={(e) => { setSelectedSlot(e.target.value) }}>
                        <option>Select Time Slot</option>
                        {slots?.map((t, key) => (
                            <option key={key} value={t.timeEnd}>{t.timeSlotEnd}</option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}

export default BookingSlot;
