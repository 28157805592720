import React, { useEffect } from "react"
import { StarIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";
import ProductCardLoader from "../../loader/ProductCardLoader";

const Products = ({ productsData, displayProducts, productsImgUrl }) => {
  // console.log('displayProducts :>> ', displayProducts);
  const products = displayProducts && displayProducts.map(product => {
    return (
      <Link key={product.id} to={`/products_view/${product.id}`} className="bg-white sm:p-4 rounded-lg shadow-xl p-2">
        {product.image ? <img className="w-full rounded-md" src={productsImgUrl + product.image} alt={product.name} /> : <img className="w-full rounded-md object-cover object-center" src={process.env.PUBLIC_URL + '/images/placeholder.png'} alt='' />}
        <div className="details sm:p-4 p-2 border-t-1">
          {product.prices[0].offer_percentage > 0 && 
            <span className="badge">{product.prices[0].offer_percentage}% OFF</span>
          }
        {product.name ?
          <div className="Review flex products-center mt-4">
            <div className="text-orange text-md flex products-center">{product.review_rating || "4.9"} <StarIcon className="h-5 w-5 ml-1" /></div>  <span className="d-block text-gray-400 sm:text-md text-sm ml-3 hidden sm:block">{product.review_rating_count || "1"} Reviews</span>
          </div> : ""}
        <h4 className="sm:text-xl my-2 text-sm">{product.name}</h4>
        <div className="price-digit flex gap-1 items-center">
        <strong className="sm:text-2xl text-sm text-red font-normal block">₹{product.prices[0].original_price}</strong>
            {(product.prices[0].original_price > product.prices[0].discount_price) &&
              <strong className="sm:text-1xl text-sm text-gray font-normal block line-through">{product.prices[0].original_price}</strong>
            }
          </div>
        </div>
      </Link>
    );
  });

  const skeltonLoader = () => {
    const loaderCount = 8;
    let myArray = []
    for (let i = 0; i < loaderCount; i++) {
      myArray.push(<ProductCardLoader />)
    }
    return myArray
  }

  return (<>
    {products ? products : skeltonLoader()}
  </>
  )
}

export default Products
