import React from 'react'

function PrivacyPolicy() {
    return (
        <div className="py-10 px-20 border-b">
            <div className="container">

                <p><strong>Privacy Policy:</strong></p>
                <p>The User hereby consents, expresses and agrees that he has read and fully understands the Privacy Policy of Mayavicakes.com in respect of the Website. The user further consents that the terms and contents of such Privacy Policy are acceptable to him.</p>
                <p>&nbsp;</p>
                <p><strong>Limited User:</strong></p>
                <p>The User agrees and undertakes not to reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website. Limited reproduction and copying of the content of the Website are permitted provided that Mayavi's name is stated as the source and prior written permission of Mayavicakes.com is sought. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of the Website is not permitted.</p>
                <p>&nbsp;</p>
                <p><strong>User Conduct and Rules:</strong></p>
                <p>You agree and undertake to use the Website and the Service only to post and upload messages and material that are proper. By way of example, and not as a limitation, you agree and undertake that when using a Service, you will not:</p>
                <br /><ol className="list-decimal ml-6">
                    <li>defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others</li>
                    <li>publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information</li>
                    <li>upload files that contain software or other material protected by intellectual property laws unless you own or control the rights thereto or have received all necessary consents</li>
                    <li>upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer</li>

                    <li>conduct or forward surveys, contests, pyramid schemes or chain letters</li>

                    <li>download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such a manner</li>

                    <li>falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded</li>

                    <li>violate any code of conduct or other guidelines, which may be applicable for or to any particular Service</li>

                    <li>violate any applicable laws or regulations for the time being in force in or outside India; and</li>

                    <li>violate any of the terms and conditions of this Agreement or any other terms and conditions for the use of the Website contained elsewhere herein</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Contents Posted on Site:</strong></p>
                <p>Except as expressly provided in these Terms of Use, no part of the Website and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, Website or another medium for publication or distribution or for any commercial enterprise, without Mayavi's express prior written consent.</p>
                <p>You may use the information on the products and services purposely made available on the Website for downloading, provided that You</p>
                <p>(1) do not remove any proprietary notice language in all copies of such documents,</p>
                <p>(2) use such information only for your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media,</p>
                <p>(3) make no modifications to any such information, and</p>
                <p>(4) do not make any additional representations or warranties relating to such documents.</p>
                <p>&nbsp;</p>
                <p>You shall be responsible for any notes, messages, e-mails, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information posted or transmitted to the Website (collectively, " Content"). Such Content will become our property and you grant us the worldwide, perpetual and transferable rights in such Content. We shall be entitled to, consistent with our Privacy Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include the Content You provide. You agree that any Content You post may be used by us, consistent with our Privacy Policy and Rules of Conduct on Site as mentioned herein, and you are not entitled to any payment or other compensation for such use.</p>
                <p><br /> </p>
                <p><strong>User Warranty and Representation:</strong></p>
                <p>The user guarantees, warrants, and certifies that you are the owner of the content which you submit or otherwise authorized to use the content and that the content does not infringe upon the property rights, intellectual property rights or other rights of others. You further warrant that to your knowledge, no action, suit, proceeding, or investigation has been instituted or threatened relating to any content, including trademark, trade name service mark, and copyright formerly or currently used by you in connection with the Services rendered by Mayavicakes.com.</p>
                <p>&nbsp;</p>
                <p><strong>Intellectual Property Rights:</strong></p>
                <br /><ol className="list-decimal ml-6">
                    <li>Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, Mayavicakes.com owns all Intellectual Property Rights to and into the Website, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, trademarks, trade names, service marks, designs, know-how, trade secrets and inventions (whether patentable or not), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks. You acknowledge and agree that you shall not use, reproduce or distribute any content from the Website belonging to Mayavicakes.com without obtaining authorization from Mayavicakes.com</li>

                    <li>Notwithstanding the foregoing, it is expressly clarified that you will retain ownership and shall solely be responsible for any content that you provide or upload when using any Service, including any text, data, information, images, photographs, music, sound, video or any other material which you may upload, transmit or store when making use of our various Service. However, with regard to the product customization Service (as against other Services like blogs and forums) you expressly agree that by uploading and posting content on to the Website for public viewing and reproduction/use of your content by third party users, you accept the User whereby you grant a non-exclusive license for the use of the same</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Links to Third Party Sites</strong>:</p>
                <p>The Website may contain links to other websites ("Linked Sites").The Linked Sites are not under the control of Mayavicakes.com or the Website and Mayavicakes.com is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Mayavicakes.com is not responsible for any form of transmission, whatsoever, received by you from any Linked Site. Mayavicakes.com is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Mayavicakes.com or the Website of the Linked Sites or any association with its operators or owners including the legal heirs or assigns thereof. The users are requested to verify the accuracy of all information on their own before undertaking any reliance on such information.</p>
                <p>&nbsp;</p>
                <p><strong>Disclaimer Of Warranties/Limitation Of Liability:</strong></p>
                <p>Mayavicakes.com has endeavoured to ensure that all the information on the Website is correct, but Mayavicakes.com neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, product or Service. In no event shall Mayavicakes.com be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from (a) the use or the inability to use the Services; (b) unauthorized access to or alteration of the user's transmissions or data; (c) any other matter relating to the services; including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Website or Service. Neither shall Mayavicakes.com be responsible for the delay or inability to use the Website or related services, the provision of or failure to provide Services, or for any information, software, products, services and related graphics obtained through the Website, or otherwise arising out of the use of the website, whether based on contract, tort, negligence, strict liability or otherwise. Further, Mayavicakes.com shall not be held responsible for the non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the website that may occur due to technical reasons or for any reason beyond Mayavi's control. The user understands and agrees that any material and/or data downloaded or otherwise obtained through the Website is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data.</p>
                <p>&nbsp;</p>
                <p>This Website, all the materials and products (including but not limited to software) and services, included on or otherwise made available to You through this site are provided on an "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the foregoing paragraph, Mayavi does not warrant that:</p>
                <ul>
                    <li>This Website will be constantly available, or available at all; or</li>
                </ul>
                <ul>
                    <li>The information on this Website is complete, true, accurate or non-misleading.</li>
                </ul>
                <p>&nbsp;</p>
                <p>Mayavi will not be liable to You in any way or in relation to the Contents of, or use of, or otherwise in connection with, the Website.</p>
                <p>Mayavi do not warrant that this site; information, Content, materials, product (including software) or services included on or otherwise made available to You through the Website; their servers; or electronic communication sent from us are free of viruses or other harmful components. Nothing on the Website constitutes or is meant to constitute, advice of any kind.</p>
                <p>You will be required to enter a valid phone number while placing an order on the Website. By registering your phone number with us, You consent to be contacted by us via phone calls and/or SMS notifications. We will not use your personal information to initiate any promotional phone calls or SMS.</p>
                <p>&nbsp;</p>
                <p><strong>Indemnification</strong>:</p>
                <p>You agree to indemnify, defend and hold harmless Mayavicakes.com from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Mayavicakes.com that arise out of, result from, or maybe payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by you pursuant to these Terms.</p>
                <p>&nbsp;</p>
                <p><strong>Termination:</strong></p>
                <br /><ol className="list-decimal ml-6">
                    <li>a) Mayavicakes.com may suspend or terminate your use of the Website or any Service if it believes, in its sole and absolute discretion that you have breached a term of these Terms.</li>

                    <li>b) If you or Mayavicakes.com terminates your use of the Website or any Service, Mayavicakes.com may delete any content or other materials relating to your use of the Service and Mayavicakes.com will have no liability to you or any third party for doing so.</li>

                    <li>c) You shall be liable to pay for any service or product that you have already ordered till the time of Termination by either party whatsoever.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Governing Law:</strong></p>
                <p>These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Bangalore.</p>
                <p>&nbsp;</p>
                <p><strong>Interpretation of Number And Genders:</strong></p>
                <p>The Terms herein shall apply equally to both the singular and plural form of the terms defined. Whenever the context may require, any pronoun shall include the corresponding masculine and feminine. The words "include", "includes" and "including" shall be deemed to be followed by the phrase "without limitation". Unless the context otherwise requires, the terms "herein", "hereof", "hereto", "hereunder" and words of similar import refer to the Terms as a whole.</p>
                <p>&nbsp;</p>
                <p><strong>Severability:</strong></p>
                <p>If any provision of the Terms is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of these Terms shall continue to be in full force and effect.</p>
                <p>&nbsp;</p>
                <p><strong>Report Abuse:</strong></p>
                <p>As per these Terms, users are solely responsible for every material or content uploaded to the Website. Mayavicakes.com does not review the contents in any way before they appear on the Website. Mayavicakes.com does not verify, endorse or otherwise vouch for the contents of any user or any content generally posted or uploaded onto the Website. Users can be held legally liable for their contents and may be held legally accountable if their contents or material include, for example, defamatory comments or material protected by copyright, trademark, etc. If you come across any abuse or violation of these Terms, please report to info@Mayavi.in</p>
                <p>&nbsp;</p>
                <p><strong>Product Description:</strong></p>
                <p>Mayavi we do not warrant that the Product description or other content of this Website is accurate, complete, reliable, current, or error-free and assumes no liability in this regard.</p>
                <p>&nbsp;</p>
                <p><strong>Limitation of Liability:</strong></p>
                <p>IN NO EVENT SHALL MAYAVI BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE, EVEN IF THE USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                <p>&nbsp;</p>
                <p><strong>Pricing:</strong></p>
                <p>Prices for products are described on our Website and are incorporated into these Terms by reference. All prices are Indian rupees. Prices, products and Services may change at Mayavi's discretion.</p>
                <p>&nbsp;</p>
                <p><strong>Payment:</strong></p>
                <p>While availing any of the payment method/s available on the Website, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:</p>
                <br /><ol className="list-decimal ml-6">
                    <li>Lack of authorization for any transaction/s, or</li>

                    <li>Exceeding the preset limit mutually agreed by you and between your "Bank/s", or</li>

                    <li>Any payment issues arising out of the transaction, or</li>

                    <li>Decline of transaction for any other reason/s.</li>
                </ol>
                <p>&nbsp;</p>
                <p>All payments made against the purchases/services on the Website by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. The website will not facilitate transactions with respect to any other form of currency with respect to the purchases made on the Website. Before shipping / delivering your order to you, we may request you to provide supporting documents (including but not limited to Govt. issued ID and address proof) to establish the ownership of the payment instrument used by you for your purchase. This is done in the interest of providing a safe online shopping environment to our users.</p>
                <p>&nbsp;</p>
                <p><strong>Acceptance of orders:</strong></p>
                <br /><ol className="list-decimal ml-6">
                    <li>The orders are accepted via the website, Android app, IOs app.</li>

                    <li>Only in case of special requirements we accept orders by mail and Customer care phone.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Exactness Not Guaranteed:</strong></p>
                <p>Mayavicakes.com hereby disclaims any guarantees of exactness as to the finish and appearance of the final Product as delivered to the recipient.</p>
                <p>&nbsp;</p>
                <p>We DO NOT guarantee delivery of the exact replica of the products mentioned on the website.</p>
                <br /><ol className="list-decimal ml-6">
                    <li>In the Case of flower-related products - the florists use different wrapping paper, baskets, decoration material, so the arrangements are shown on the website look a little different from actual products delivered. Delivery of exactly the same variety or colour of flowers is also subject to availability and freshness. We may choose to deliver a bouquet or basket of the same monetary value due to unavoidable circumstances.</li>

                    <li>In the case of cakes - the bakeries use the pictures as guidelines but they have their own style of preparing the same flavoured cakes. Therefore the cakes though are same flavoured might look different. In case the cake you ordered cannot be delivered due to unavoidable circumstances, we will deliver a similar cake of the same monetary value.</li>

                    <li>In the case of any other products, if we cannot deliver the same products due to unavoidable circumstances, we will deliver similar products of the same monetary value.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Products and services delivery - Date and Time slots:</strong></p>
                <p>The time slots provided at the time of checkout can be selected by the user. These are the preferred dates and time slots of customers. We strive to do deliveries on the selected date and time slot but it is NOT A GUARANTEE. We are committed to doing delivery of products and services ordered until 24 hours of the requested delivery date and time slot. Failing to do so, we will refund the amount, not before that.</p>
                <br /><ol className="list-decimal ml-6">
                    <li>For daytime delivery, delivery time ranges from 10 AM to 8 PM</li>

                    <li>For midnight orders, delivery could be made anytime between 11:00 PM to 12:30 AM.</li>

                    <li>In the case of remote locations/small towns it can be made from 10 PM to 12:30 AM.</li>

                    <li>No refund will be made in case orders fail to be delivered within provided time slot.</li>

                    <li>Only one attempt for delivery will be made. Please confirm the availability of the recipient before choosing the delivery time. If the recipient is not available at the time of delivery, the second attempt will not be made and the order will be cancelled. No refund will be made in such a case.</li>

                    <li>Delivery timings, if promised cannot be guaranteed in case of special circumstances like extreme weather conditions, riots, strikes, elections, bandhs, during rush days like Valentine's Day, Mother&rsquo;s Day, Rakhi, New year Eve etc. The order cancellation would not be possible in such circumstances and the order will be executed on consecutive days.</li>

                    <li>Special day deliveries are processed until 52 hours of the requested delivery date and time slots.</li>
                </ol>
                <p>&nbsp;</p>
                <p>Special days for Mayavi are - Mother&rsquo;s Day, Father&rsquo;s Day, Diwali, Holi, Valentine&rsquo;s Day, Rose day, Teddy day, Proposal Day, Hug day, Kiss Day, Friendship Day, Raksha Bandhan, Christmas and New Year.</p>
                <p>&nbsp;</p>
                <p><strong>Delivery:</strong></p>
                <p>Title and risk of loss for all products ordered by you shall pass on to you upon Mayavi's shipment. Thereafter Mayavi will not be responsible for any loss or damage to the product.</p>
                <p>&nbsp;</p>
                <p>If the recipient receives the products in damaged condition</p>
                <br /><ol className="list-decimal ml-6">
                    <li>Recipient should check the products delivered (cakes, flowers, any other) before signing the receipt.</li>

                    <li>If the product delivered found damaged, the recipient should refuse to accept it and immediately inform Customer Care.</li>

                    <li>Any issues with the products delivered should be reported within 4 hours of receipt, after that no request for replacement or refund will be entertained.</li>

                    <li>In case of badly damaged products, a replacement will be provided. Kindly allow a minimum of 6 hours for the replacement.</li>

                    <li>Courier product complaints should be informed within 48 hours after delivery. Complaints coming after 48 hours will not be undertaken.</li>
                </ol>
                <p>&nbsp;</p>
                <p>Minimalistic and cosmetic damages like -</p>
                <br /><ol className="list-decimal ml-6">
                    <li>Name/ message on cake not written.</li>

                    <li>Name and message not attached with flowers.</li>

                    <li>Cream of cake/decoration on cake/flowers packaging issues - will not be eligible for refunds/replacements unless they are severely damaged.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Keep as surprise</strong></p>
                <p>For orders where Keep as Surprise is selected, we will deliver the order as requested without the receiver knowing the sender&rsquo;s detail. In case the receiver refuses to accept the order then we will disclose the sender&rsquo;s details for the order to be acknowledged and accepted by the receiver. If still the receiver does not accept the order then the order will be returned and it will be considered as Complete from Mayavi&rsquo;s end. Due to time limitations and the gift items being of perishable nature, therefore, we will attempt our best to have the order delivered within a given timeframe.</p>
                <p>&nbsp;</p>
                <p><strong>Contact Us:</strong></p>
                <p>Please send any questions or comments (including all inquiries unrelated to copyright infringement) regarding this Website to info@mayavicakes.com</p>
                <p>Customer care - Call us at +91 - 95398 00051</p>
                <p>Available between 8 AM - 11 PM</p>
                <p>&nbsp;</p>
                <p><strong>Non-delivery of products</strong></p>
                <p>We will be able to attempt delivery of your order only once. For perishable products, a delivery attempt can be made again subject to our decision and you may be liable for additional delivery charges in such cases. In the event the delivery is not executed during the attempt, the customer shall still be charged for the order and no redelivery will be possible. We will consider the order executed in the below cases:</p>
                <br /><ol className="list-decimal ml-6">
                    <li>Wrong shipping/delivery address.</li>

                    <li>Premises locked.</li>

                    <li>Wrong landlines / Mobile number or Extension number which may render us unable to reach the recipient for the delivery.</li>

                    <li>Recipient refusing to accept the delivery.</li>

                    <li>Delivered the product at the Gate / Neighbor as per instruction given by the receiver.</li>

                    <li>Recipient not available.</li>
                </ol>
                <p>&nbsp;</p>

            </div>
        </div>
    )
}

export default PrivacyPolicy
