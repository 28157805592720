import { LOCATIONS_LOADING, LOCATIONS_LOAD_ERROR, LOCATIONS_LOAD_SUCCESS } from "../../constants/actionTypes";

const locations = (state, { payload, type }) => {
    switch (type) {
        case LOCATIONS_LOADING: {
            return {
                ...state,
                locations: {
                    ...state.locations,
                    loading: true,
                },
            }
        }
        case LOCATIONS_LOAD_SUCCESS: {
            return {
                ...state,
                locations: {
                    ...state.locations,
                    loading: false,
                    data: payload,
                },
            }
        }
        case LOCATIONS_LOAD_ERROR: {
            return {
                ...state,
                locations: {
                    ...state.locations,
                    loading: false,
                    error: payload
                },
            }
        }
        default:
            return state;
    }
}


export default locations;