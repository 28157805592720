import React, { useState } from "react";
import PageBanner from "../components/common/PageBanner";
import Button from "../components/button/button";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import { Link, useHistory } from "react-router-dom";
import { emailApp, phoneNumberApp } from "../constants/config";
import { useForm } from "react-hook-form";
import axiosInstance from "../helpers/axiosInstance";
import toast, { Toaster } from 'react-hot-toast';

function Help() {
  const history = useHistory()
  const { register, formState: { errors }, handleSubmit, reset } = useForm();

  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    // console.log('data :>> ', data);
    setLoading(true)
    axiosInstance(history).post('api/v1/app/send_message', data)
      .then((res) => {
        //console.log(`res`, res)
        if (res.data.status) {
          setLoading(false)
          reset()
          if (res.data.action_status) {
            toast.success("Message Sent Successfully")
          } else {
            toast.error("Please try again")
          }
        }
      }).catch((err) => {
        //console.log(`err`, err)
      })
  }

  return <div className="border-b pb-20">
    <Toaster />
    <div className="container">
      <PageBanner title="How Can We Help?" />
      <div className="mb-10">
        <div className="sm:flex items-center justify-center">
          <a href={`tel:${phoneNumberApp}`} className="relative block mx-3 mb-4 sm:mb-0" target="_blank">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange text-white">
                <PhoneIcon className="h-6 w-6" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-black">Talk To Us</p>
            </dt>
            <dd className="ml-16 text-base text-brown">{phoneNumberApp}</dd>
          </a>
          <a href={`mailto:${emailApp}`} className="relative block mx-3" target="_blank">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange text-white">
                <MailIcon className="h-6 w-6" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-black">Happy to Help</p>
            </dt>
            <dd className="ml-16 text-base text-brown">{emailApp}</dd>
          </a>
        </div>
      </div>
      <div className="bg-gray-50 p-10 rounded-xl lg:w-6/12 m-auto">
        <h1 className="text-2xl mb-4">Get In Touch!</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm text-brown mb-2">Full Name</label>
              <input className="border w-full h-12 rounded outline-none px-3" type="text" {...register("name", { required: true, maxLength: { value: 25, message: "Maximum Limit exceeds" } })} />
              {errors.name?.type === 'required' && <span className={"text-red"}>First name is required</span>}
            </div>
            <div>
              <label className="block text-sm text-brown mb-2">Email</label>
              <input className="border w-full h-12 rounded outline-none px-3" type="email"  {...register("email", { required: "Email Required", pattern: { value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Enter Valid Email" } })} id="" />
              {errors.email && <span className={"text-red"}>{errors.email.message}</span>}
            </div>
            <div>
              <label className="block text-sm text-brown mb-2">Phone Number</label>
              <input className="border w-full h-12 rounded outline-none px-3" type="tel"  {...register("phone", { required: "Phone Number Required", maxLength: { value: 10, message: "Please Enter Valid Phone Number" }, pattern: { value: /\d+/, message: "Please Enter Valid Number" } })} id="" />
              {errors.phone && <span className={"text-red"}>{errors.phone.message}</span>}
            </div>
            <div>
              <label className="block text-sm text-brown mb-2">Subject</label>
              <input className="border w-full h-12 rounded outline-none px-3" type="text"  {...register("subject", { required: true })} id="" />
              {errors.subject?.type === 'required' && <span className={"text-red"}>Subject is required</span>}
            </div>
            <div className="sm:col-span-2">
              <label className="block text-sm text-brown mb-2">Your Message</label>
              <textarea className="border w-full h-16 rounded outline-none px-3 resize-none" type="text" {...register("message")} id=""></textarea>
            </div>
            <div className="sm:col-span-2">
              <button disabled={loading} className={`w-full lg:w-auto text-white hover:bg-darkRed, hover:text-white, py-3 px-8 rounded-lg inline-block cursor-pointer text-center ${loading ? "bg-red cursor-not-allowed" : "bg-red"}`} type="submit" >Send Message {loading && <span class="ml-2">
                <i class="fas fa-circle-notch fa-spin fa-1x"></i>
              </span>}</button>
              {/* <Button link="/" bgColor="red" textColor="white" text="Send Message" bgHover="darkRed" /> */}
            </div>
          </div>
        </form>
      </div>



    </div>
  </div>
}

export default Help;