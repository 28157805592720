import React from "react"
import Body from "../components/body/Body"

function Home() {
    return <>
        <Body />
    </>

}

export default Home