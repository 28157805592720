import React, { useContext, useEffect, useState } from 'react'
import ProductCard from '../products/product/ProductCard'
import CategoryListSidebar from './category/CategoryListSidebar'
import PageBanner from '../common/PageBanner'
import PriceRangeFilter from './product/PriceRangeFilter'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { GlobalContext } from '../../context/Provider'
import getProducts from '../../context/actions/getProducts'
import ReactPaginate from 'react-paginate';

export default function Products() {

   const { productsState: {
      products: { data, error, loading }
   }, productsDispatch } = useContext(GlobalContext)

   const history = useHistory()
   const location = useLocation()
   const { catId } = useParams();

   const [products, setProducts] = useState([])
   const [productsImgUrl, setProductsImgUrl] = useState("")
   const [filterSelectedCategory, setFilterSelectedCategory] = useState(0)
   const [filterSelectedCategoryName, setFilterSelectedCategoryName] = useState("")
   const [filterPriceRange, setFilterPriceRange] = useState([])
   const [filteredProducts, setFilteredProducts] = useState([])
   const [pageNumber, setPageNumber] = useState(0)

   const productPerPage = 9;
   const pagesVisited = pageNumber * productPerPage

   const pageCount = Math.ceil(filteredProducts.length / productPerPage)

   const displayProducts = filteredProducts?.slice(pagesVisited, pagesVisited + productPerPage)
   // console.log('displayProducts :>> ', displayProducts);

   const { categoriesState: {
      categories: { data: categoryData, error: categoryError, loading: categoryLoading }
   } } = useContext(GlobalContext)

   useEffect(() => {
      getProducts(history)(productsDispatch);
   }, []);

   useEffect(() => {
      setProducts(data.products)
      setProductsImgUrl(data.product_base_url)
      if (catId) {
         setFilterSelectedCategory(parseInt(catId))
      }
      location.state?.subCatName && setFilterSelectedCategoryName(location.state.subCatName)
   }, [data])


   const filtered_products = products?.filter((item) => {
      if (filterSelectedCategory > 0 && filterPriceRange?.length > 0) {
         if (filterSelectedCategory > 0) {
            if (item.category == filterSelectedCategory) {
               if (filterPriceRange[0] <= item.prices[0].original_price && filterPriceRange[1] >= item.prices[0].original_price) {
                  return item
               }
            }
         } else {

         }
      } else {
         if (filterPriceRange?.length > 0) {
            if (filterPriceRange[0] <= item.prices[0].original_price && filterPriceRange[1] >= item.prices[0].original_price) {
               return item
            }
         } else {

         }

      }
   })

   useEffect(() => {
      if (filtered_products) {
         setFilteredProducts(filtered_products)
      }
   }, [filterPriceRange, filterSelectedCategory])

   const handlePageClick = ({ selected }) => {
      setPageNumber(selected)
   }

   return <div className="product-page border-b pb-20">
      <div className="container">
         <PageBanner title={filterSelectedCategoryName ? filterSelectedCategoryName : "Products"} />
         <div className="grid lg:grid-cols-4 md:grid-cols-5 gap-6">
            <div className="bg-white border p-6 rounded-2xl md:col-span-2 lg:col-auto">
               <h2 className="text-xl text-red mb-4">Categories</h2>
               <CategoryListSidebar categoryData={categoryData} setFilterSelectedCategoryName={setFilterSelectedCategoryName} setFilterSelectedCategory={setFilterSelectedCategory} filterSelectedCategory={filterSelectedCategory} />
               <h2 className="text-xl text-red mb-12 border-t pt-5 mt-5">Price Range</h2>
               <PriceRangeFilter productsData={products} filterPriceRange={filterPriceRange} setFilterPriceRange={setFilterPriceRange} />
            </div>
            <div className="md:col-span-3">
               <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 sm:gap-8 gap-3">
                  <ProductCard productsData={products} displayProducts={displayProducts} productsImgUrl={productsImgUrl} />
               </div>
               <div className="mt-6">
                  {
                     displayProducts.length > 6 &&
                     <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={1}
                        // pageRangeDisplayed={2}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        previousLinkClassName={'previousBttn'}
                        nextLinkClassName={'nextBttn'}
                        disabledClassName={'paginationDisabled'}
                        activeClassName={'paginationActive'}
                     />
                  }
               </div>
            </div>
         </div>
      </div>
   </div>
}





