import { LOCATIONS_LOADING, LOCATIONS_LOAD_ERROR, LOCATIONS_LOAD_SUCCESS } from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstance from "../../helpers/axiosInstance"


export default (history) => (dispatch) => {
    dispatch({
        type: LOCATIONS_LOADING,
    });
    axiosInstance(history)
        .get('/api/v1/app/get_locations')
        .then(res => {
            // console.log('locationRes :>> ', res);
            dispatch({
                type: LOCATIONS_LOAD_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            // console.log('locerr :>> ', err);
            dispatch({
                type: LOCATIONS_LOAD_ERROR,
                payload: err.response ? err.response.data : CONNECTION_ERROR,
            });
        })
}


