import React from "react"
import { Link } from "react-router-dom"
import CategoryCardLoader from "../loader/CategoryCardLoader"


function CategoryCard({ categoriesData, selectedCategory }) {
    console.log('categoriesData :>> ', categoriesData);
    console.log('selectedCategory :>> ', selectedCategory);
    const filtered_categories = categoriesData.category?.filter((item) => {
        if (selectedCategory > 0) {
            return item.id == selectedCategory
        } else {
            return item
        }
    })

    const skeltonLoader = () => {
        const loaderCount = 6;
        let myArray = []
        for (let i = 0; i < loaderCount; i++) {
            myArray.push(<CategoryCardLoader />)
        }
        return myArray
    }

    return (<>
        {filtered_categories ? filtered_categories?.map((item) => (
            <Link key={item.id} to={`/products/${item.id}`} className="bg-white p-4 rounded-lg pb-0 shadow-xl">
                <img className="w-full rounded-md object-cover" src={categoriesData.category_base_url + item.image} alt={item.name} />
                <h4 className="text-lg my-4">{item.name}</h4>
            </Link>
        )) : skeltonLoader()
        }
    </>
    )
}

export default CategoryCard;