import { CLEAR_PROFILE_DATA, PROFILE_DATA_ERROR, PROFILE_DATA_LOADING, PROFILE_DATA_LOAD_SUCCESS } from "../../constants/actionTypes"

const profile = (state, { payload, type }) => {
    switch (type) {
        case PROFILE_DATA_LOADING: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: true,
                    error: null,
                }
            }
        }
        case PROFILE_DATA_LOAD_SUCCESS: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: false,
                    data: payload,
                },
            }
        }
        case PROFILE_DATA_ERROR: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: false,
                    error: payload
                },
            }
        }

        case CLEAR_PROFILE_DATA: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: false,
                    error: payload,
                    data: null
                },
            }
        }

        default:
            return state;
    }
}


export default profile;