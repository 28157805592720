import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import CheckOut from '../pages/CheckOut'

function ProtectedRoute({ isAuth: isAuth, component: Component, cart: cart, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuth) {
                    if (Component === CheckOut) {
                        if (cart?.items?.length > 0) {
                            return <Component isAuth={isAuth} />
                        } else {
                            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                        }
                    } else {
                        return <Component isAuth={isAuth} />
                    }
                } else {
                    return (
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                    )
                }
            }}
        />
    )
}

export default ProtectedRoute
