import React from "react"
import Slider from "react-slick";
function Stats() {
   var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      rows: 2,
      variableWidth: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 4000,
      responsive: [
         {
           breakpoint: 768,
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1,
             rows: 1
           }
         }
       ]
  };
   return (
    <div className="safety-sec py-12 m-pt-20-0">
    <div className="container">
       <div className="lg:grid lg:grid-cols-3 lg:gap-4">
          <div className="lg:flex lg:items-center mb-5 lg:mb-0">
            <div> 
              <h2 className="lg:text-2xl md:text-lg">Your safety is our priority</h2>
              <p className="text-sm text-gray-400">Steps we are taking to keep you safe</p>
            </div> 
          </div>   
          <div className="col-span-2">
            <div className="stats">
            <Slider {...settings}>
            
            <div className="item">
               <div className="flex items-center">
                  <div className="border p-3 rounded-xl"><img className="w-10 h-10" src={process.env.PUBLIC_URL + '/images/birthday-cake.png'} alt="#"  /></div> 
                  <p className="xl:text-xl lg:text-base ml-4">Freshly Prepared</p>
               </div>
            </div>
            <div className="item">
               <div className="flex items-center">
                  <div className="border p-3 rounded-xl"><img className="w-10 h-10" src={process.env.PUBLIC_URL + '/images/online-payment.png'} alt="#" /></div> 
                  <p className="xl:text-xl lg:text-base ml-4">Online Payment/COD</p>
               </div>
            </div>
            <div className="item">
               <div className="flex items-center">
                  <div className="border p-3 rounded-xl"><img className="w-10 h-10" src={process.env.PUBLIC_URL + '/images/icon_2.svg'} alt="#" /></div> 
                  <p className="xl:text-xl lg:text-base ml-4">Contact Less Delivery</p>
               </div>
            </div>
            <div className="item">
               <div className="flex items-center">
                  <div className="border p-3 rounded-xl"><img className="w-10 h-10" src={process.env.PUBLIC_URL + '/images/icon_4.svg'} alt="#" /></div> 
                  <p className="xl:text-xl lg:text-base ml-4">Compulsory Mask and Gloves</p>
               </div>
            </div>
               </Slider>
            </div>    
          </div> 
       </div>    
    </div> 
</div>
   )

}

export default Stats;