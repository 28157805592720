import React, { useContext, useEffect } from 'react'
import Stats from './stats/Stats'
import OfferBanner from './offerBanner/offerBanner'
import CategoryHome from './category/CategoryHome'
import ProductsPopular from './products/ProductsPopular'
import Testimonials from './testimonials/Testimonials'
import DownloadApp from './downloadApp/DownloadApp'
import getBanners from '../../context/actions/getBanners'
import { GlobalContext } from '../../context/Provider'
import { useHistory } from 'react-router-dom'
import getPopularProducts from '../../context/actions/getPopularProducts'

function Body() {
    const history = useHistory()
    const { bannersState, bannersDispatch } = useContext(GlobalContext)
    const { popularProductsState, popularProductsDispatch } = useContext(GlobalContext)

    useEffect(() => {
        getBanners(history)(bannersDispatch);
        getPopularProducts(history)(popularProductsDispatch)
    }, [])
    return (
        <div className="Body">
            <OfferBanner bannersState={bannersState} />
            <Stats />
            {/* <CategoryHome /> */}
            <ProductsPopular popularProductsState={popularProductsState} />
            <Testimonials />
            <DownloadApp />
        </div>
    )
}

export default Body