import React, { Component } from 'react';
import { SearchIcon } from '@heroicons/react/outline'

class Search extends Component {
  render() {
    return    <div className="bg-gray-100 rounded-md h-14 m-0 flex">
    <span className="w-auto flex justify-end items-center text-gray-500 ml-3">
<SearchIcon className="h-5 w-5 text-gray-400" />
</span>
<input className="w-full rounded p-2 bg-transparent outline-none focus:ring-0" type="text" placeholder="Try 'Strawberry Cake, Vanilla Cake'" />
</div>
  }
}

export default Search;