/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { CakeIcon, MoonIcon, FireIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

export default function Example() {
  return (<>
    <div className="relative bg-white overflow-hidden">

      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 top-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight text-black sm:text-5xl md:text-5xl">
                {/* <span className="block xl:inline">Everything we bake, </span>{' '}
                <span className="block xl:inline">we bake with love.</span> */}
                <span className="block xl:inline">Feeling to have a cake? </span>{' '}
                <span className="block xl:inline">Looking for a delicious cake in the city?</span>
              </h1>
              <p className="mt-3 text-base text-black opacity-50 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">


                Yes! You're on the lookout for us.<br />

                We are Mayavi Cakes, and we can provide you with the highest quality cakes in a professional manner.<br /><br />

                You can contact Mayavi at any time to place an order for cakes for any happy event, such as birthdays, anniversaries, triumph celebrations, weddings, housewarming, career achievement, and so on.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link
                    to="/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red hover:bg-darkRed md:py-4 md:text-lg md:px-10"
                  >
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={process.env.PUBLIC_URL + '/images/about-bg.jpg'} alt="#" />
      </div>
    </div>
    {/* <div className="py-20 border">
      <div className="max-w-4xl center text-center center m-auto px-4">
        <h2 className="text-3xl text-black">Every flavor has a story.</h2>
        <p className="text-base text-black opacity-50 mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
      </div>
      <div className="container mt-8">
        <div className="grid lg:grid-cols-3 gap-8 md:grid-cols-1">
          <div className="bg-white border p-12 rounded-2xl">
            <CakeIcon className="h-14 w-14 text-red mb-10" />
            <h4 className="text-xl text-black">Best Theme Cakes Store</h4>
            <p className="text-base text-black opacity-50 mt-2">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
          <div className="bg-white border p-12 rounded-2xl">
            <MoonIcon className="h-14 w-14 text-red mb-10" />
            <h4 className="text-xl text-black">Midnight Delivery Service</h4>
            <p className="text-base text-black opacity-50 mt-2">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
          <div className="bg-white border p-12 rounded-2xl">
            <FireIcon className="h-14 w-14 text-red mb-10" />
            <h4 className="text-xl text-black">Freshly Prepared</h4>
            <p className="text-base text-black opacity-50 mt-2">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
        </div>
      </div>
    </div> */}
  </>
  )
}
