import React, { useContext, useEffect, useState } from 'react';
import { TrashIcon } from "@heroicons/react/solid"
import FeatherIcons from 'feather-icons-react'
import { Link, NavLink } from 'react-router-dom'
import Button from '../components/button/button';
import PageBanner from '../components/common/PageBanner';
import { GlobalContext } from '../context/Provider';
import ProductQuantityCart from '../components/products/product/ProductQuantityCart';
import { useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import toast, { Toaster } from 'react-hot-toast';
import axiosInstanceProfile from '../helpers/axiosInstanceProfile';
import getPromoCodes from '../context/actions/getPromoCodes';


export default function Cart() {

   const history = useHistory()
   const { cart, setCart } = useContext(GlobalContext)

   const { promoCodeDataState: { promoCode: { data: resPromoCode, error: resPromoCodeError, loading: resPromoCodeLoad } }, promoCodeDataDispatch } = useContext(GlobalContext)

   const [cartItems, setCartItems] = useState([])
   const [promoCode, setPromoCode] = useState("");
   const [validPromoCode, setValidPromoCode] = useState({});
   const [apiProcessing, setApiProcessing] = useState(false);

   useEffect(() => {
      getPromoCodes(history)(promoCodeDataDispatch)
   }, [])



   useEffect(() => {
      if (cart.items?.length > 0) {
         setCartItems(cart.items)
      }
      if (cart.promoCode?.code) {
         setValidPromoCode(cart.promoCode)
      }
   }, [cart])

   const getCartSubTotal = (items) => (items?.reduce((total, item) => total + parseInt(item.price), 0))

   const subTotal = getCartSubTotal(cart.items);

   useEffect(() => {
      if (cart.items?.length > 0 && cart.promoCode?.code) {
         validatePromoCode(cart.promoCode?.code, subTotal)
            .then(code => {
               setToLocalStorage(cart.items, subTotal, code)
            })
            .finally(function () {
               setApiProcessing(false)
            });
      }
   }, [])

   const setToLocalStorage = (items, subTotal, promo) => {
      let cartCopy = {
         'items': items,
         'subTotal': subTotal,
         'promoCode': promo ?? {}, //res?.promo_code 
      }
      setCart(cartCopy);
      let stringCart = JSON.stringify(cartCopy);
      var encryptedCart = CryptoJS.AES.encrypt(stringCart, 'secret-key@123').toString();
      localStorage.setItem("cart", encryptedCart)
   }

   const validatePromoCode = async (code, total) => {
      if (code != "") {
         setApiProcessing(true)
         let validCode = resPromoCode?.promocodes?.filter(function (promo) {
            return promo.code == code;
         })[0];
         if (validCode) {
            // console.log('code :>> ', code, validCode, total);
            if (parseInt(validCode.min_amount) <= total) {
               var discount = 0;
               if (validCode.type == "Percentage") {
                  discount = total * parseInt(validCode.amount) / 100;
               } else {
                  discount = validCode.amount;
               }
               if (discount > parseInt(validCode.max_discount_amount)) {
                  discount = validCode.max_discount_amount;
               }
               total -= discount;
               let promoCodeFinal = {
                  id: validCode.id,
                  code: validCode.code,
                  discount: discount,
                  total: total
               };
               setValidPromoCode(promoCodeFinal)
               return promoCodeFinal;
            } else {
               setValidPromoCode({})
               clearPromoField()
               return {};
            }
         } else {
            setValidPromoCode({})
            clearPromoField()
            return {};
         }

      } else {
         setValidPromoCode({})
         toast.error("Please Enter Promo Code.")
      }
      // try {
      //    if (code != "") {
      //       setApiProcessing(true)
      //       let res = await axiosInstanceProfile(history).post('/api/v1/app/check_promo_code', {
      //          'code': code,
      //          'total': total,
      //       })
      //       if (res.data.action_status) {
      //          setValidPromoCode(res.data.promo_code)
      //          toast.success('Promo Code Applied Successfully.')
      //       } else {
      //          setValidPromoCode({})
      //          clearPromoField()
      //          res.data.message ? toast.error(res.data.message) : toast.error('Invalid Promo Code')
      //       }
      //       return res.data
      //    } else {
      //       setValidPromoCode({})
      //       toast.error('Please Enter Promo Code.')
      //    }
      // }
      // catch (err) {
      //    console.error(err);
      // }
   }

   const removeCartItem = (itemID, kg) => {
      let cartItemsCpy = [...cart.items]
      cartItemsCpy = cartItemsCpy.filter((item) => {
         if (item.id == itemID) {
            return item.kg !== kg
         } else {
            return item.id !== itemID
         }
      });
      let finalSubTotal = getCartSubTotal(cartItemsCpy)

      if (validPromoCode?.code || promoCode != "") {
         validatePromoCode(validPromoCode?.code ?? promoCode, finalSubTotal)
            .then(code => {
               setToLocalStorage(cartItemsCpy, finalSubTotal, code)
            })
            .finally(function () {
               setApiProcessing(false)
            });
      } else {
         setToLocalStorage(cartItemsCpy, finalSubTotal, {})
      }
   }


   const clearPromoField = () => {
      setPromoCode("");
      setValidPromoCode({});
      setToLocalStorage(cart.items, subTotal, {})
   }

   return (
      <div className="product-page border-b pb-10 sm:pb-20">
        <Toaster />
         <div className="container">
            <PageBanner title="cart" />
            {cart.items?.length > 0 ?
               <div className="grid sm:grid-cols-1 md:grid-cols-5 xl:grid-cols-4 gap-6">
                  <div className="md:col-span-3">
                     <div>
                        <span className="block text-base mb-5">Total Cart Items {cartItems?.length}</span>
                        <div className="grid gap-6">
                           {cartItems?.map((product, index) => (
                              <div key={index} className="border p-4 rounded-xl sm:flex md:inline lg:flex items-center relative">
                                 <div className="absolute top-8 right-8 sm:top-4 sm:right-4 cursor-pointer"><TrashIcon onClick={() => removeCartItem(product.id, product.kg)} className="w-10 h-10 text-center text-red p-2 bg-lightRed rounded-full hover:bg-red hover:text-white transition duration-500 ease-in-out" /></div>
                                 <div className="mb-5 lg:mb-0 sm:mr-4 lg:mr-8"><img className="sm:w-60 rounded" src={product.image} alt="" /></div>
                                 <div className="w-full">
                                    <h6 className="text-xl sm:pr-10 md:pr-0 lg:pr-4 xl:pr-0">{product.name}
                                       <span className="inline-block ml-2 bg-orange text-sm text-white px-2 rounded">{product.kgName}</span>
                                    </h6>
                                    <div className="lg:flex justify-between xl:inline">
                                       <span className="block text-2xl text-red mt-2">₹{product.price}</span>
                                       <ProductQuantityCart
                                          product={product}
                                          validPromoCode={validPromoCode}
                                          setApiProcessing={setApiProcessing}
                                          validatePromoCode={validatePromoCode}
                                          setToLocalStorage={setToLocalStorage}
                                       />
                                    </div>
                                 </div>

                           </div>
                        ))}
                     </div>
                  </div>
               </div>
               <div className="md:col-span-2 xl:col-auto bg-gray-50 rounded-2xl p-8">
                  <span className="block text-base mb-3">Order Summary</span>
                  <div className="flex items-center justify-between border-b py-2">
                     <span className="text-gray-400">Subtotal</span>
                     <span className="text-brown">₹{subTotal}</span>
                  </div>
                  {/* <div className="flex items-center justify-between border-b py-2">
                     <span className="text-gray-400">Tax 5%</span>
                     <span className="text-brown">₹61</span>
                  </div> */}
                  <div className="flex items-center justify-between border-b py-2">
                     <span className="text-gray-400">Delivery Charge</span>
                     <span className="text-white bg-green text-sm px-2 rounded">FREE</span>
                  </div>
                     {
                        validPromoCode?.discount ? <div className="flex items-center border-b justify-between py-2">
                           <span className="text-gray-400">Promo Code Discount</span>
                           <span className="text-black">₹{validPromoCode?.discount ?? 0}</span>
                        </div> : ''
                     }
                     <div className="flex items-center justify-between py-2">
                        <span className="text-gray-400">Total</span>
                        <span className="text-black">₹{validPromoCode?.total ?? subTotal}</span>
                     </div>

                     <div className="mt-4 flex">
                        <input className={"p-2 w-full rounded-l-lg h-11" + (validPromoCode?.id ? "cursor-not-allowed" : "")} type="text" onChange={(e) => { setPromoCode(e.target.value) }} value={validPromoCode?.code ?? promoCode} placeholder="Enter Promo Code..." disabled={validPromoCode?.id ? "disabled" : ""} />
                        <button onClick={() => validPromoCode?.id ? clearPromoField() : validatePromoCode(promoCode, subTotal).then(code => {
                           code?.id ? toast.success('Promo Code Applied Successfully.') : toast.error('Invalid Promo Code.');
                           setToLocalStorage(cart.items, subTotal, code ?? {})
                        }).finally(() => { setApiProcessing(false) })} class="rounded-r-lg bg-brown px-5 text-white">{apiProcessing ?
                           <i class="fas fa-circle-notch fa-spin fa-1x"></i> : validPromoCode?.id ? <i class="fa fa-times"></i> : <i class="fa fa-plus"></i>}</button>
                     </div>

                     <div className="mt-4">
                        <Button width="w-full" bgHover="darkRed" click={(e) => (cartItems?.length > 0 && !apiProcessing) ? history.push("/checkout") : e.preventDefault()} text="Checkout Now" bgColor="red" textColor="white" disabled={apiProcessing} />
                        <div className="h-4"></div>
                        <Button width="w-full" bgHover="gray-200" link="/" text="Continue Shopping" bgColor="gray-100" textColor="black" />
                     </div>
                  </div>
                  <div>
                  </div>
               </div>
               :
               <div class="no-data text-center">
                  <img className="empty-ico" src={process.env.PUBLIC_URL + '/images/noresult.svg'} alt="#" />
                  <h1 className="text-2xl">Your Cart is Empty</h1>
                  <p className="text-sm text-gray-400 pb-2">Add something to make me happy :)</p>
                  <Link className="bg-red text-white hover:bg-darkRed hover:text-undefined py-3 px-8 rounded-lg inline-block cursor-pointer text-center text-sm mt-3" to="/">Continue Shopping</Link>
               </div>
            }
         </div>
      </div>
   );
}