import { BANNERS_LOADING, BANNERS_LOAD_ERROR, BANNERS_LOAD_SUCCESS } from "../../constants/actionTypes";

const banners = (state, { payload, type }) => {
    switch (type) {
        case BANNERS_LOADING: {
            return {
                ...state,
                banners: {
                    ...state.banners,
                    loading: true,
                },
            }
        }
        case BANNERS_LOAD_SUCCESS: {
            return {
                ...state,
                banners: {
                    ...state.banners,
                    loading: false,
                    data: payload,
                },
            }
        }
        case BANNERS_LOAD_ERROR: {
            return {
                ...state,
                banners: {
                    ...state.banners,
                    loading: false,
                    error: payload
                },
            }
        }
        default:
            return state;
    }
}


export default banners;