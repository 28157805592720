import React, { createContext, useEffect, useReducer, useState } from 'react';
import bannersInitialState from './initialstates/bannersInitialState';
import categoriesInitialState from './initialstates/categoriesInitialState';
import insertOrderInitialState from './initialstates/insertOrderInitialState';
import locationsInitialState from './initialstates/locationsInitialState';
import popularProductsInitialState from './initialstates/popularProductsInitialState';
import ProductsInitialState from './initialstates/ProductsInitialState';
import banners from './reducers/banners';
import categories from './reducers/categories';
import order from './reducers/insertOrder';
import locations from './reducers/locations';
import popularProducts from './reducers/popularProducts';
import Products from './reducers/products';
import profile from './reducers/profileData';
import ProfileDataInitialState from './initialstates/ProfileDataInitialState';
import PromoCodeDataInitialState from './initialstates/PromoCodeDataInitialState';
import promoCode from './reducers/promoCodeData';

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
    const [categoriesState, categoriesDispatch] = useReducer(
        categories,
        categoriesInitialState
    );

    const [bannersState, bannersDispatch] = useReducer(
        banners,
        bannersInitialState
    );

    const [popularProductsState, popularProductsDispatch] = useReducer(
        popularProducts,
        popularProductsInitialState
    );

    const [locationsState, locationsDispatch] = useReducer(
        locations,
        locationsInitialState
    );

    const [productsState, productsDispatch] = useReducer(
        Products,
        ProductsInitialState
    );

    const [cart, setCart] = useState({
        items: [],
        subTotal: 0,
        promoCode: {}
    });
    const [user, setUser] = useState();


    const [insertOrderState, insertOrderDispatch] = useReducer(
        order,
        insertOrderInitialState
    );

    const [profileDataState, profileDataDispatch] = useReducer(
        profile,
        ProfileDataInitialState
    );

    const [promoCodeDataState, promoCodeDataDispatch] = useReducer(
        promoCode,
        PromoCodeDataInitialState
    );

    return (<GlobalContext.Provider
        value={
            {
                categoriesState,
                categoriesDispatch,
                bannersState,
                bannersDispatch,
                popularProductsState,
                popularProductsDispatch,
                locationsState,
                locationsDispatch,
                productsState,
                productsDispatch,
                cart,
                setCart,
                user,
                setUser,
                insertOrderState,
                insertOrderDispatch,
                profileDataState,
                profileDataDispatch,
                promoCodeDataState,
                promoCodeDataDispatch
            }
        }>
        {children}
    </GlobalContext.Provider>)

};


