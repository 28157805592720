import { CLEAR_INSERTED_ORDER, INSERT_ORDER_ERROR, INSERT_ORDER_LOADING, INSERT_ORDER_LOAD_SUCCESS } from "../../constants/actionTypes";

const order = (state, { payload, type }) => {
    switch (type) {
        case INSERT_ORDER_LOADING: {
            return {
                ...state,
                order: {
                    ...state.order,
                    loading: true,
                    error: null,
                }
            }
        }
        case INSERT_ORDER_LOAD_SUCCESS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    loading: false,
                    data: payload,
                },
            }
        }
        case INSERT_ORDER_ERROR: {
            return {
                ...state,
                order: {
                    ...state.order,
                    loading: false,
                    error: payload
                },
            }
        }

        case CLEAR_INSERTED_ORDER: {
            return {
                ...state,
                order: {
                    ...state.order,
                    loading: false,
                    error: payload,
                    data: null
                },
            }
        }

        default:
            return state;
    }
}


export default order;