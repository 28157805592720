
import React, { useContext, useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { CashIcon, HomeIcon, ShoppingBagIcon, StarIcon } from '@heroicons/react/solid'
import PageBanner from '../components/common/PageBanner'
import Dashboard from '../components/myaccount/Dashboard'
import EditProfile from '../components/myaccount/EditProfile'
import Orders from '../components/myaccount/Orders'
import Wallet from '../components/myaccount/Wallet'
import MyReviews from '../components/myaccount/MyReviews'
import { useHistory, withRouter } from 'react-router-dom'
import { LogoutIcon } from '@heroicons/react/outline'
import { GlobalContext } from '../context/Provider'
import getProfileData from '../context/actions/getProfileData'


function MyAccount() {

  const history = useHistory()
  const { user, setUser } = useContext(GlobalContext)
  const { setCart } = useContext(GlobalContext)

  const { profileDataState: { profile: { data: resProfile, error: resProfileError, loading: resProfileLoad } }, profileDataDispatch } = useContext(GlobalContext)
  // console.log('resProfile :>> ', resProfile);

  const handleLogout = async () => {
    localStorage.removeItem('user')
    localStorage.removeItem('cart')
    setUser("");
    setCart({
      items: [],
      subTotal: 0,
      promoCode: {}
  });
    history.push('/')
  }

  useEffect(() => {
    getProfileData(history)(profileDataDispatch)
  }, [])

  const [state, setState] = useState(false)

  return (
    <div>
      <div className="pb-20 border-b">
        <div className="container">
          <PageBanner title="My Account" />
          <Tabs>
            <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-8">
              <div className="bg-gray-50 rounded-2xl p-8">
                <h2 className="block text-xl mb-2">My Account</h2>
                <TabList>
                  <Tab>
                    <div className="cursor-pointer flex items-center text-brown border-b py-3 hover:text-black transition duration"><HomeIcon className="w-5 text-gray-400 mr-2" />Dashboard</div>
                  </Tab>
                  <Tab>
                    <div className="cursor-pointer flex items-center text-brown border-b py-3 hover:text-black transition duration" ><ShoppingBagIcon className="w-5 text-gray-400 mr-2" />Orders</div>
                  </Tab>
                  {/* <Tab>
                       <div className="cursor-pointer flex items-center text-brown border-b py-3 hover:text-black transition duration" ><CashIcon className="w-5 text-gray-400 mr-2"/>Wallet<div className="block ml-2 bg-green text-white px-2 rounded text-sm">$ 12.00</div></div>  
                     </Tab>   */}
                  <Tab>
                    <div className="cursor-pointer flex items-center text-brown border-b py-3 hover:text-black transition duration"><StarIcon className="w-5 text-gray-400 mr-2" />My Reviews</div>
                  </Tab>
                </TabList>
                <div className="cursor-pointer flex items-center text-brown pt-3 hover:text-black transition duration" onClick={handleLogout}><LogoutIcon className="w-5 text-gray-400 mr-2" />Logout</div>

              </div>
              <div className="md:col-span-2 lg:col-span-3">
                <TabPanel>
                  <Dashboard state={state} setState={setState} user={user} userData={resProfile?.user_data} resProfileLoad={resProfileLoad}/>
                </TabPanel>
                <TabPanel>
                  <Orders orders={resProfile?.orders} imgUrl={resProfile?.product_base_url}  resProfileLoad={resProfileLoad}/>
                </TabPanel>
                {/* <TabPanel>
                  <Wallet />
                </TabPanel> */}
                <TabPanel>
                  <MyReviews myReviews={resProfile?.user_reviews} />
                </TabPanel>
              </div>
            </div>
          </Tabs>
        </div>
        {
          resProfile?.user_data && <EditProfile state={state} setState={setState} userData={resProfile?.user_data} />
        }
        

      </div>
    </div>
  )
}

export default withRouter(MyAccount);

