import React from 'react'

function ProductSlider(props) {
    const { data, tab, myRef, imgIndex, imgUrl, offerPercent, isLoading } = props

    const LoaderMultiple = () => {
        const loaderCount = 5;
        let myArray = []
        for (let i = 0; i < loaderCount; i++) {
            myArray.push(<div className="rounded bg-gray-200 animate-pulse h-20 opacity-50 transition duration-500 ease-in-out transform"></div>)
        }
        return myArray
    }
    return (
        <div className='product-slide relative'>
            {
                offerPercent > 0 && <span class="badge">{offerPercent} %OFF</span>
            }

            {
                isLoading ? <>
                    <div class="lg:h-96 rounded bg-gray-200 animate-pulse md:h-72 w-full"></div>
                    <div className="grid grid-cols-6 gap-3 mt-5" >
                        {LoaderMultiple()}
                    </div>
                </> :
                    data?.images ? <>
                        {data?.images[imgIndex] ? <img className="rounded" src={data?.images ? imgUrl + data?.images[imgIndex] : ""} alt={data?.images ? data.name : "Image"} /> : <img className="w-full rounded-md object-cover object-center" src={process.env.PUBLIC_URL + '/images/placeholder.png'} alt='' />}
                        <div className="grid grid-cols-6 gap-3 mt-5" ref={myRef}>

                            {data?.images?.map((img, index) => (
                                <img key={index} className="rounded cursor-pointer opacity-50 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" src={imgUrl + img} alt={img.name}
                                    onClick={() => tab(index)} />
                            ))}

                        </div>
                    </> :
                        <>
                            <div class="lg:h-96 rounded bg-gray-200 animate-pulse md:h-72 w-full"></div>
                            <div className="grid grid-cols-6 gap-3 mt-5" >
                                {LoaderMultiple()}
                            </div>
                        </>
            }

        </div>
    );
}

export default ProductSlider