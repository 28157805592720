import React from 'react'

function RefundPolicy() {
    return (
        <div className="py-10 px-20 border-b">
            <div className="container">

                <p><strong>Cancellation Policy</strong></p>
                <br />
                <p>Please read the below-stated points for any cancellations.</p>
                <p>Please understand that the points mentioned in the policies are with reference to the time slots on the <a href="http://www.mayavicakes.com/">www.mayavicakes.com</a> website selected by the user in the checkout process.</p>
                <p>Orders are processed in time slots and we accept cancellation requests with reference to the time slots selected by customers in the order placed. Consider conditions stated below -</p>
                <br />
                <ol className="list-decimal ml-6">
                    <li>If the cancellation request comes to Mayavi before 24 hours to the start of the time slot selected - 10% of the order value is charged as cancellation charges. 90% refund to Bank Account. NOTE - 100% refund can be requested in Mayavi wallet, where we waive off cancellation charges.</li>
                    <li>If the cancellation request comes to Mayavi between 12 - 24 hours before the start of the time slot selected then - 20% of the order value will be deducted as cancellation charges. 80% refund to Bank Account. NOTE - 100% refund can be requested in Mayavi wallet, where we waive off cancellation charges.</li>

                    <li>If a cancellation is request comes to Mayavi within 12 hours to the start of the time slot selected - 100% of the order value will be deducted as cancellation charges. 0% refund to Bank account or 0% to Mayavi wallet applicable.</li>
                </ol>
                <br />
                <p>In any of the cases above Wallet refunds and Bank, refunds can be initiated only at the discretion of the company considering the reasons of cancellation like unfortunate events, calamities or unavoidable circumstances. No claims to refund can be made by the customer to overrule above stated clause.</p>
                <br />
                <p>NO cancellation requests are accepted on orders placed in the following categories.</p>
                <br />
                <ol className="list-decimal ml-6">
                    <li>Same day delivery category - If your date of placing an order and delivery date of that order is the same, we do not accept cancellation requests. If you cancel it, or the receiver does not receive it - you are not entitled to any refund.</li>

                    <li>Same-day mid-night delivery orders cannot be cancelled.</li>

                    <li>Special day orders cannot be cancelled and no refund is initiated for them.</li>
                </ol>
                <br />
                <p><strong>Same day Orders</strong></p>
                <p>We allow users to select the same date of delivery on which he/she is placing the order i.e. order delivery and order placed date is same. We attempt to do same-day delivery of the requested products but DO NOT PROMISE so.</p>
                <p>We will process same-day delivery orders within 24 hours of the requested date and time slot.</p>
                <br />
                <ol className="list-decimal ml-6">
                    <li>Mayavi takes same-day orders for day and evening time slots till 5 pm. It is an attempt to do same-day delivery and not a promise, though we make our 100% effort to deliver on the same date as the order placed.</li>
                    <li>Mayavi takes orders for same-day mid-night (00:00) delivery till 7:00 pm. It is attempted to do same day mid-night delivery and not a promise, though we make our 100% effort to deliver on the same date as order placed.</li>
                </ol>
                <p><br /> </p>
                <p><strong>Refund Policy</strong></p>
                <br />

                <p>Refunds in cases of Cancellations are mentioned in the Cancellation policy, kindly refer to the same.</p>
                <p>The acceptance of the order is subject to the availability of products, delivery areas and time. The order may or may not be accepted. The order might be cancelled after assessing the circumstances and communicated to the customer. In such a case amount paid by the customer is fully refunded to Mayavi wallet or bank account.</p>
                <p>Only one attempt for delivery will be made. Please confirm the availability of the recipient before choosing the delivery time. If the recipient is not available at the time of delivery, the second attempt will not be made and the order will be cancelled. No refund will be made in such a case.</p>
                <p>The refunds are only initiated once the customer confirms over mail that where the refund has to be done - Mayavi wallet or bank account.</p>
                <p>Refund will be credited in Mayavi Wallet on orders which are processing within 48 hours of the delivery date</p>
                <p>Refunds are processed to your payment method within 15 working days.</p>
                <br />
                <p><strong>Address Change requests</strong></p>
                <p>Change in the address is permitted free of cost only before 24 hours of delivery.</p>
                <br />
                <ol className="list-decimal ml-6">
                    <li>Within 24 hours period before delivery, if the address is changed we charge Rs 200/-.</li>

                    <li>Sometimes the address change request cannot be accepted, in that case, the order will not be refunded or cancelled.</li>
                </ol>
                <br />
                <p><strong>Pin code Requirements</strong></p>
                <p>It is the user responsibility to provide the correct pin code at the address of delivery. The pin code should map to the correct delivery address. Sometimes address mentioned does not correspond to the pin code of that area.</p>
                <p>If the pin code entered in the address section is incorrect then the following cases arise -</p>
                <br />
                <ol className="list-decimal ml-6">
                    <li>Address entered is of Non-Serviceable area, the order stands cancelled and NO refund will be initiated - The system checks pin code for delivery verification and may accept an order when pin code entered is of serviceable area and address is not.</li>

                    <li>If our team contacts you regarding the wrong pin in address, and you want to get the delivery done to the new pin code, the charges of the newly added pin code will apply. The order cannot be cancelled and refunded in case you do not wish to pay for the correct pin code&rsquo;s charges.</li>
                </ol>
                <br />
                <p><strong>Important Points</strong></p>
                <p>The products and services displayed on Mayavi are our best offerings and we put in our 100% effort to deliver high-quality similar products and services. These terms and conditions are put across to avoid any possible conflict.</p>
                <br />
                <ol className="list-decimal ml-6">
                    <li>All products listed on the website, their descriptions, and their prices are subject to change, time to time according to the various conditions prevailing in the market.</li>
                    <li>Orders are accepted and delivered in TIME SLOTS which are available in your selected city. All requests are processed with reference to the time slot of your order.</li>
                    <li>For midnight orders, delivery could be made anytime between 11:00 PM to 12:30 AM. In the case of remote locations/small towns, it can be made from 10 PM to 12:30 AM.</li>
                    <li>Mayavi reserves the right, to modify, suspend, or discontinue the sale of any product or services with or without prior notice at any time.</li>
                    <li>The product specifications (weight, size, colour etc.) mentioned with the product photos displayed are only approximate and indicative. There may be a slight variation in the pictures and the products delivered.</li>
                    <li>The acceptance of the order is subject to the availability of products, delivery areas and time. The order may or may not be accepted. The order might be cancelled after assessing the circumstances and communicated to the customer. In such a case amount paid is fully refunded.</li>
                    <li>In case we detect fraudulent behaviour, we are liable to cancel the order at our discretion and we will not be refund money in such cases.</li>
                    <li>If the duplicate orders with the similar details are placed, we will try to contact the customer, and in case of no response from the customer, we will process both the order. Any argument rose after that will not be entertained.</li>
                    <li>There is no guarantee of delivery of the products in the time slots mentioned on the website in case of midnight delivery on special occasions like New Year, Christmas, Valentine Day. Mother&rsquo;s Day etc. The orders will be processed and delivered up to 52 hours of the requested date and time slot of the order.</li>
                    <li>There is no cancellation for orders placed under the &lsquo;Same Day Delivery category.</li>
                    <li>Only one attempt for delivery will be made. Please confirm the availability of the recipient before choosing the delivery time. If the recipient is not available at the time of delivery, the second attempt will not be made and the order will be cancelled. No refund will be made in such a case.</li>
                    <li>For rescheduling orders, you need to inform us at least 24 hours before the delivery date.</li>
                    <li>Recipient should check the order before signing the receipt, If found damaged, the recipient should refuse to accept the order and the delivery person will return with a replacement. Kindly allow a minimum of 6 hours for the replacement.</li>
                    <li>Execution of the order will be considered successful and complete in cases like Wrong Address mentioned by sender, premises where delivery has to be made is locked, recipient of the order is not available, and the recipient has refused to receive the order due to personal reasons ( NOT due to products or services of Mayavi).</li>
                    <li>Execution of the order will be considered successful and complete in case of permission not granted to our delivery executive to enter the delivery location. For example, the orders where delivery locations are premises like hospitals, Govt agencies, societies, resorts hotels, any personal or public property, etc. and the recipient number is not reachable.</li>
                    <li>Delivery timings, if promised cannot be guaranteed in case of special circumstances like extreme weather conditions, riots, strikes, elections, bandhs, during rush days like Valentine's Day, Mother&rsquo;s Day, Rakhi, New year Eve etc. The order cancellation would not be possible in such circumstances and the order will be executed on consecutive days.</li>
                    <li>In case you feel that the product received does not meet your expectations, you must bring it to the notice of our customer service within 4 hours of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
                </ol>

            </div>
        </div>
    )
}

export default RefundPolicy
