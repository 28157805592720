import axios from "axios";

export default (history = null) => {
    const baseURL = process.env.REACT_APP_BACKEND_URL;

    let headers = {
        "tokenvalid": "Token T8oaioN9iK7IxI6ACtYgTZIkOOTiducEnAtX0UX1sm0hJjMSdk9y3ibvI6hpfnhYeZeEGGwEj7BDiHjuuSK2uVWwvijqmJBxqluQq4PzllyW97Owh9cib7ZJCIr0SnwU7KQI8ZCW1xvH4ilJLJ0vm2FDfSnn9ZQ3Ih0AoWjshyGmrEmDqElKpg8nzOSxcT1H28T7Zt20fVFcoOQ9F4IbyHBYofFEo4Td8irK7LvCHAuPUbbqLMip3KrvGWA",
    };

    // if (localStorage.token) {
    //     headers.tokenvalid = `Token ${localStorage.token}`;
    // }

    const axiosInstance = axios.create({
        baseURL,
        headers
    });


    axiosInstance.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            if (error.response.status === 403) {
                console.log("403 Error");
                // localStorage.removeItem("token");

                if (history) {
                    history.push('/')
                } else {
                    window.location = "/"
                }

            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        }
    )
    return axiosInstance;
};
