export default function createSlots(slotConfig) {
    const {
        configSlotHours,
        configSlotMinutes,
        configSlotPreparation,
        timeArr
    } = slotConfig;

    let defaultDate = new Date().toISOString().substring(0, 10);
    let slotsArray = [];
    let _timeArrStartTime;
    let _timeArrEndTime;
    let _tempSlotStartTime;
    let _endSlot;
    let _startSlot;

    for (var i = 0; i < timeArr.length; i++) {
        _timeArrStartTime = new Date(
            (defaultDate + " " + timeArr[i].startTime).replace(/\s+/g, 'T')
        ).getTime();
        _timeArrEndTime = new Date(
            (defaultDate + " " + timeArr[i].endTime).replace(/\s+/g, 'T')
        ).getTime();
        _tempSlotStartTime = _timeArrStartTime;

        while (
            new Date(_tempSlotStartTime).getTime() <
            new Date(_timeArrEndTime).getTime()
        ) {
            _endSlot = new Date(_tempSlotStartTime);
            _startSlot = new Date(_tempSlotStartTime);

            _tempSlotStartTime = _endSlot.setHours(
                parseInt(_endSlot.getHours()) + parseInt(configSlotHours)
            );
            _tempSlotStartTime = _endSlot.setMinutes(
                parseInt(_endSlot.getMinutes()) + parseInt(configSlotMinutes)
            );

            if (
                new Date(_tempSlotStartTime).getTime() <=
                new Date(_timeArrEndTime).getTime()
            ) {
                slotsArray = [...slotsArray, {
                    timeSlotStart: new Date(_startSlot).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true
                    }),
                    timeSlotEnd: _endSlot.toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true
                    }),
                    timeEnd: _endSlot.toTimeString().split(" ")[0]
                }];
                
                // slotsArray.push({
                //     timeSlotStart: new Date(_startSlot).toLocaleTimeString("en-US", {
                //         hour: "numeric",
                //         minute: "numeric",
                //         hour12: true
                //     }),
                //     timeSlotEnd: _endSlot.toLocaleTimeString("en-US", {
                //         hour: "numeric",
                //         minute: "numeric",
                //         hour12: true
                //     }),
                //     // timeEnd: _endSlot.toLocaleTimeString("en-US", {
                //     //     hour: "numeric",
                //     //     minute: "numeric",
                //     //     hour12: false
                //     // }),
                //     timeEnd: _endSlot.toTimeString().split(" ")[0]
                // }); 
            } 

            _tempSlotStartTime = _endSlot.setMinutes(
                _endSlot.getMinutes() + parseInt(configSlotPreparation)
            );
        }
    }
    return slotsArray;
}
