import React, { useEffect, useState } from 'react'
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

function PriceRangeFilter({ productsData, filterPriceRange, setFilterPriceRange }) {
  const products = productsData

  // const [val, setVal] = useState(filterPriceRange || []);

  // console.log('filterPriceRange :>> ', filterPriceRange);

  function getInitialPriceRange() {
    // if(products?.length > 1){
    //   console.log('products :>> ', products);
    //   const largestPrice = products?.reduce((p, c) =>
    //   {
    //     console.log('p :>> ', p);
    //     //p.prices[0].original_price > c.prices[0].original_price ? p.prices[0].original_price : c.prices[0].original_price
    //   });

    //   const smallestPrice = products?.reduce((p, c) =>
    //   {
    //     console.log('p :>> ', p);
    //     //p.prices[0].original_price > c.prices[0].original_price ? p.prices[0].original_price : c.prices[0].original_price
    //   });

    //   // const largestPrice = products?.reduce((p, c) => p.prices[0].original_price > c.prices[0].original_price ? p.prices[0].original_price : c.prices[0].original_price);
    //   // const smallestPrice = products?.reduce((p, c) => p.prices[0].original_price < c.prices[0].original_price ? p.prices[0].original_price : c.prices[0].original_price);
    //   console.log('smallestPrice :>> ', smallestPrice);
    //   console.log('largestPrice :>> ', largestPrice);
    //   return [smallestPrice, largestPrice]
    // }else{
      return [100, 5000]
    //}
  }


  useEffect(() => {
    if (products) {
      const initialPriceRange = getInitialPriceRange()
      setFilterPriceRange(initialPriceRange)
      // setVal(initialPriceRange)
    }
  }, [products])


  return (
    <div className="sliderArea mb-5">
      {/* <p>{filterPriceRange[0]} - {filterPriceRange[1]}</p> */}

      <Range
        marks={{
          100: `₹ 100`,
          5000: `₹ 5000`
        }}
        min={100}
        max={5000}
        // defaultValue={[val[0], val[1]]}
        value={filterPriceRange}
        tipFormatter={value => `₹${value}`}
        tipProps={{
          placement: "top",
          visible: true
        }}
        onChange={setFilterPriceRange}
      />
    </div>
  );
}

export default PriceRangeFilter
